// Page headers for the site - stored in a scalable fashion
export const defaultMetaTags = {
    title: 'Off the Curriculum',
    description: 'Default description',
    url: 'https://default-url.com',
    image: 'https://default-url.com/default-image.jpg',
    siteName: 'Off the Curriculum',
    twitterCard: 'summary_large_image',
    twitterSite: '@otc_london',
    fbAppId: 'default_fb_app_id',
  };
  
  export const pageMetaTags = {
    home: {
      title: 'Off the Curriculum',
      description: 'Description for the home page',
      url: 'https://offthecurriculum.co.uk/home',
      image: 'https://your-site.com/home-image.jpg',
    },
    about: {
      title: 'About Off the Curriculum',
      description: 'Learn more about us',
      url: 'https://offthecurriculum.co.uk/about',
      image: 'https://your-site.com/about-image.jpg',
    },
    signIn: {
        title: 'Sign to EDUCO with Off the Curriculum',
        description: 'Learn more about us',
        url: 'https://offthecurriculum.co.uk/SignIn',
        image: 'https://your-site.com/about-image.jpg',
      },
    createAUserProfile: {
      title: 'Create A User Profile', 
      description: 'Create your user profile', 
      url: 'https://offthecurriculum.co.uk/CreateAUserProfile',
      image: 'https://your-site.com/about-image.jpg',
    }, 
    forgotPassword: {
      title: 'Forgot Password', 
      description: 'Forgot your user password', 
      url: 'https://offthecurriculum.co.uk/ForgotPassword',
      image: 'https://your-site.com/about-image.jpg',
    },
    Loading: {
      title: 'Loading: Educo page', 
      description: 'Loading: Educo page', 
      url: 'https://offthecurriculum.co.uk/Loading',
      image: 'https://your-site.com/about-image.jpg',
    },
    ExploreEduco: {
      title: 'Explore page', 
      description: 'Educo page', 
      url: 'https://offthecurriculum.co.uk/Explore',
      image: 'https://your-site.com/about-image.jpg',
    }
      // Add page headers data below.... 
  };
  