import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'; 
import useAuth from "../../../Firebase/Auth";
import { emailsHasher } from "../../../Firebase/educoFunctions";
import { functions } from "../../../Firebase/firebaseConfig";
import { httpsCallable } from "firebase/functions";
// Icons
import { FaAngleDown, FaAngleUp, FaCheck, FaCalendarAlt, FaLock } from "react-icons/fa";
import ChatWithMentor from "./Components/ChatWithMentor/ChatWithMentor";
import StandardButtonContainer from "../../../Components/Common/Buttons/StandardButtons/StandardButtons";
import { FaFileWaveform } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const MentorProgrammePage = () => {
  // Fix the state variable name to match
  const [mentorMenteeData, setMentorMenteeData] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [expandedStages, setExpandedStages] = useState({ 0: true });
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Fix the state variable name
  const currentUser = useAuth();
  const navigate = useNavigate();

  const handleChatClick = (e) => {
    // Prevent the event from bubbling up to parent components
    e.stopPropagation();
    //console.log('Chat clicked, current state:', isChatOpen);
    
    // Toggle the chat state to open it if currently closed
    setIsChatOpen(true);
  };

  useEffect(() => {
    const getMentorData = async () => {
      if (currentUser) {
        try {
          setIsLoading(true);
          const emailHash = emailsHasher(currentUser.email);
          
          // Make sure the data is properly structured
          const getMentorMenteeInfo = httpsCallable(functions, 'get_your_mentor_mentee_info');
          const result = await getMentorMenteeInfo({ 
            userId: emailHash 
          });
          //console.log('Function result here:', result);
    
          if (result.data.success) {
            setMentorMenteeData(result.data.epq_data);
          } else {
            setError(result.data.error || 'Failed to fetch mentor data');
          }
        } catch (error) {
          console.error("Error fetching mentor/mentee info:", error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      }
    };

    getMentorData();
  }, [currentUser]);


  const [mentorInfo, setMentorInfo] = useState({
    name: "Dr. Jane Smith",
    specialization: "Quantum Physics",
    yearsOfExperience: 15,
    bio: "Dr. Jane Smith is a renowned expert in Quantum Physics with over 15 years of experience in research and teaching. She has published numerous papers in top-tier journals and is passionate about mentoring the next generation of physicists.",
    achievements: [
      "Nobel Prize in Physics (2020)",
      "Fellow of the Royal Society",
      "Author of 'Quantum Mechanics: A New Perspective'"
    ],
    availableTimes: [
      { day: "Monday", time: "2:00 PM - 4:00 PM" },
      { day: "Wednesday", time: "10:00 AM - 12:00 PM" },
      { day: "Friday", time: "3:00 PM - 5:00 PM" }
    ]
  });
  const toggleStage = (index) => {
    setExpandedStages((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const handleFeedbackClick = (stageIndex) => {
    navigate('/mentors/mentorprogammepage/sessionfeedbackform', {
      state: {
        stage: stageIndex + 1,
        stageName: stages[stageIndex].name,
        userId: currentUser?.email, // Assuming you want to pass user info
        schoolId: mentorMenteeData?.schoolId || '', // If you have school info in mentorMenteeData
      }
    });
  };

  const stages = [
    {
      name: "Meet the Mentee/Mentor",
      description: "Start building a strong connection with your mentor! Choose a day and time that works best for you to meet with your mentor every week. This will be your dedicated time for guidance, support, and setting your goals.",
      locked: false,
      completed: false,
    },
    {
      name: "Commission: Induction",
      description: "Your first session is an informal introduction designed to break the ice and establish a foundation for your mentoring journey. The focus of this meeting will be the creation or refinement of the mentee’s literature review. This review serves as a roadmap for outlining the mentee’s EPQ project intentions, setting clear targets, and planning future sessions. It's a great opportunity to align goals and expectations, ensuring a productive partnership moving forward.",
      locked: false,
      completed: true,
    },
    {
      name: "Progression",
      description: "Track your progress and complete checkpoints in the progression stage.",
      locked: true,
      completed: false,
    },
    {
      name: "Evaluation",
      description: "The evaluation stage will be unlocked after completing the progression stage.",
      locked: true,
      completed: false,
    },
    {
      name: "Reflection",
      description: "Reflect on your journey in the final stage of the mentorship program.",
      locked: true,
      completed: false,
    },
  ];

  const checkpoints = [
    "Catch up with your tutor to discuss about ut enim ad minima veniam, quis ut aliquip ex ea commodo.",
    "Catch up with your tutor to discuss about ut enim ad minima veniam, quis ut aliquip ex ea commodo.",
    "Catch up with your tutor to discuss about ut enim ad minima veniam, quis ut aliquip ex ea commodo.",
  ];

  return (
    <div style={styles.container}>
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <>
          <h1 style={styles.title}>Let's work together with your mentor!</h1>
          <p style={styles.subtitle}>
            Schedule calls with your mentor ({mentorInfo.name}) and start learning together.
          </p>
      {stages.map((stage, index) => (
      <div key={index} style={styles.stageContainer}>
        <div 
          onClick={() => !stage.locked && toggleStage(index)}
          style={{
            ...styles.stageHeader,
            backgroundColor: stage.completed ? '#E6F4EA' : (stage.locked ? '#F3F4F6' : 'white'),
            cursor: stage.locked ? 'default' : 'pointer',
          }}
        >
          <div style={styles.stageInfo}>
            <span style={styles.stageNumber}>Stage {index + 1}</span>
            <span style={styles.stageName}>{stage.name}</span>
            {stage.completed && <FaCheck style={styles.checkIcon} />}
          </div>
          {stage.locked ? (
            <FaLock style={styles.lockIcon} />
          ) : (
            expandedStages[index] ? <FaAngleUp /> : <FaAngleDown />
          )}
        </div>
        
        {expandedStages[index] && !stage.locked && (
        <div style={styles.stageContent}>
          <p>{stage.description}</p>
          {index === 0 && (
            <StandardButtonContainer>
              <StandardButtonContainer.ButtonTwo>
                <FaCalendarAlt style={styles.calendarIcon} />
                Schedule Sessions
              </StandardButtonContainer.ButtonTwo>
              <StandardButtonContainer.ButtonOne
                onClick={() => handleFeedbackClick(index)}
              >
                <FaFileWaveform style={styles.calendarIcon} />
                Feedback Form
              </StandardButtonContainer.ButtonOne>
            </StandardButtonContainer>
          )}
        </div>
      )}
      </div>
    ))}
    
    <div onClick={(e) => e.stopPropagation()}>
      <ChatWithMentor 
        isOpen={isChatOpen} 
        onClick={handleChatClick} 
      />
    </div>
      </>
    )}
  </div>
);
};

const styles = {
  container: {
    maxWidth: '80%',
    margin: '0 auto',
    padding: '20px',
  },
  title: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  subtitle: {
    fontSize: '16px',
    marginBottom: '20px',
    color: '#666',
  },
  stageContainer: {
    marginBottom: '15px',
    border: '1px solid #E5E7EB',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  stageHeader: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stageInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  stageNumber: {
    marginRight: '10px',
    color: '#6B7280',
  },
  stageName: {
    fontWeight: 'bold',
  },
  checkIcon: {
    color: '#34D399',
    marginLeft: '10px',
  },
  lockIcon: {
    color: '#9CA3AF',
  },
  stageContent: {
    padding: '15px',
    backgroundColor: '#F9FAFB',
  },
  scheduleButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#1F2937',
    color: 'white',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '6px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  calendarIcon: {
    marginRight: '8px',
  },
};
export default MentorProgrammePage;