import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'; // If you have framer-motion installed

function Comingsoon() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const countDownDate = new Date("November 9, 2024 18:00:00").getTime();
    
    const x = setInterval(function() {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      
      if (distance < 0) {
        clearInterval(x);
        setIsExpired(true);
        return;
      }
      
      setTimeLeft({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000)
      });
    }, 1000);

    return () => clearInterval(x);
  }, []);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
    background: 'linear-gradient(145deg, #ffffff 0%, #FAF6F0 100%)',
    borderRadius: '20px',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    margin: '40px auto',
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
  };

  const titleStyle = {
    fontSize: '28px',
    fontWeight: '800',
    marginBottom: '40px',
    lineHeight: '1.4',
    textShadow: '1px 1px 1px rgba(0,0,0,0.1)',
    padding: '0 20px',
  };

  const countdownStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '30px',
    width: '100%',
    maxWidth: '700px',
    padding: '20px',
  };

  const countdownItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 30px',
    background: 'white',
    borderRadius: '15px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.05)',
    minWidth: '120px',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    ':hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
    },
  };

  const timeValueStyle = {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#ff6b00',
    marginBottom: '8px',
    fontFamily: "'Montserrat', sans-serif",
    letterSpacing: '1px',
  };

  const labelStyle = {
    fontSize: '16px',
    fontWeight: '500',
    color: '#666',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  };

  const expiredMessageStyle = {
    fontSize: '24px',
    color: '#ff6b00',
    fontWeight: '600',
    padding: '30px',
    background: 'white',
    borderRadius: '15px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.05)',
  };

  return (
    <motion.div 
      style={containerStyle}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <motion.h2 
        style={titleStyle}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        Countdown to the profile, mentoring, communities, and course features!
      </motion.h2>
      
      {isExpired ? (
        <motion.div 
          style={expiredMessageStyle}
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          Our new features are coming soon!
        </motion.div>
      ) : (
        <div style={countdownStyle}>
          {[
            { label: 'Days', value: timeLeft.days },
            { label: 'Hours', value: timeLeft.hours },
            { label: 'Minutes', value: timeLeft.minutes },
            { label: 'Seconds', value: timeLeft.seconds }
          ].map((item, index) => (
            <motion.div 
              key={item.label}
              style={countdownItemStyle}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ transform: 'translateY(-5px)' }}
            >
              <span style={timeValueStyle}>
                {String(item.value).padStart(2, '0')}
              </span>
              <span style={labelStyle}>{item.label}</span>
            </motion.div>
          ))}
        </div>
      )}
    </motion.div>
  );
}

export default Comingsoon;