export default {
  id: 'adviceforyoungpeoplebreathworkandmeditation',
    title: "Breath work and meditation for managing stressful times",
    hero_image: "path_to_hero_image.jpg",
    author: {
      name: "Nicole Curtis",
      image: '',
      bio: "I take care of all Marketing and Communications at Off the Curriculum. I was born in London but moved to Spain as a child, growing up there until university. Currently, I am an MSc student reading Psychology and Neuroscience of Mental Health at King’s College London. I work in Child and Adolescent Mental Health and Addiction Rehabilitation. With a passion for education, I jumped at the opportunity to be a part of OTC. Curious by nature, I am passionate about mental health and wellbeing, the creative and performing arts, food, travel, and culture.",
      social: {
        twitter: "https://twitter.com/otclambeth/",
        linkedin: "https://www.linkedin.com/in/nicole-l-aa60301b5",
        instagram: "https://www.instagram.com/offthecurriculumlondon/"
      }
    },
    metadata: {
      date: "2023-02-06",
      readTime: "5 minutes",
      category: "Empowerment"
    },
    content: [
      {
        type: "paragraph",
        content: "Exams can be super stressful, right? Your palms get sweaty, your heart starts racing, and your mind goes into overdrive. But what if I told you that there's a simple and effective way to manage exam stress? It's called breath work and meditation, and trust me, it's not as hippie-dippie as it sounds. In fact, it's backed by science!"
      },
      {
        type: "paragraph",
        content: `When you're stressed, your body's fight or flight response kicks in, and your sympathetic nervous system goes into overdrive. This leads to shallow breathing, increased heart rate, and muscle tension. Not exactly the ideal state for acing an exam, right? That's where breath work and meditation come in. By consciously controlling your breath and training your mind to remain calm, you can activate your parasympathetic nervous system, which triggers the rest and digest response. This leads to the release of calming hormones like oxytocin, reducing the levels of stress hormones, and resulting in a more relaxed state.`
      },
      {
        type: "paragraph",
        content: "So, let's give it a try! Find a quiet and comfortable spot to sit in. You can sit cross-legged on the floor or in a chair with your feet planted on the ground. Take a deep breath in through your nose and exhale slowly through your mouth. Close your eyes and focus on your breath. Imagine your breath flowing in through your nose and filling up your lungs, and then slowly flowing out through your mouth. Take a few deep breaths like this, and notice how your body feels."
      },
      {
        type: "paragraph",
        content: "Now, we're going to try a simple breath work technique called the 4-7-8 breath. Inhale deeply through your nose for 4 seconds, hold your breath for 7 seconds, and then exhale slowly through your mouth for 8 seconds. Repeat this cycle for a few minutes, focusing solely on your breath."
      },
      {
        type: "heading",
        content: "Group discussion"
      },
      {
        type: "paragraph",
        content: "Group discussion is a collaborative active learning technique that involves discussing the material you are trying to learn with others. This technique can help you to identify gaps in your knowledge and gain a deeper understanding of the material through the perspectives of others. To incorporate group discussion into your revision schedule, find a study group and set aside time to discuss the material you are trying to learn."
      },
      {
        type: "paragraph",
        content: "Finally, let's try a brief meditation. Focus your attention on your breath and imagine a wave of calm washing over you with each inhale and exhale. When your mind wanders, gently bring your focus back to your breath."
      },
      {
        type: "paragraph",
        content: "There you have it! A quick and easy way to manage exam stress. And the best part? You can do this anytime and anywhere. So the next time you're feeling overwhelmed, take a few deep breaths and give this technique a try."
      },
      {
        type: "paragraph",
        content: "In conclusion, breath work and meditation are powerful tools for managing exam stress. They can help you remain calm, improve focus and concentration, and enhance overall well-being. So why not give it a try? Your mind and body will thank you."
      },
      {/*{
        type: "image",
        url: "image_url",
        caption: "Image caption"
      },*/}
      // Add more content sections as needed
    ],
    relatedArticles: [
      {
        title: "Mindfulness",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Fmindfulness.webp?alt=media&token=f8585110-94e6-42ea-8a3b-ad1979e436d4",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Mindfulness"
      },
      {
        title: "Learning to Learn from home",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Learningtolearn"
      }
    ]
  };