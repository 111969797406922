import React from "react";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  title: {
    fontSize: '2.5rem',
    color: '#1F484C',
    marginBottom: '1.5rem',
  },
  description: {
    fontSize: '1.1rem',
    lineHeight: '1.6',
    marginBottom: '1.5rem',
    textAlign: 'center',
  },
  formFrame: {
    width: '100%',
    height: '800px',
    border: 'none',
    marginTop: '2rem',
  },
  link: {
    color: '#1F484C',
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  list: {
    textAlign: 'left',
    marginTop: '1rem',
    marginBottom: '1rem',
  }
};

const Enrol = () => {
  const handleEmailProtection = (e) => {
    e.preventDefault();
    const email = 'nicolelevermore@offthecurriculum.co.uk';
    window.location.href = `mailto:${email}`;
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Enrol with Off The Curriculum</h1>
      
      <p style={styles.description}>
        Take the first step towards expanding your horisons and joining our community
        of learners and innovators.
      </p>
      
      <p style={styles.description}>
        To apply as a participant, please:
        <ol style={styles.list}>
          <li>Complete the enrolment form below</li>
          <li>Send your current CV to{' '}
            <a 
              style={styles.link}
              onClick={handleEmailProtection}
              href="#"
            >
              Nicole Levermore
            </a>
          </li>
        </ol>
        Need help with your CV? Check out these{' '}
        <a 
          style={styles.link}
          href="https://www.ucas.com/careers/getting-job/how-write-cv"
          target="_blank"
          rel="noopener noreferrer"
        >
          CV writing tips
        </a>.
      </p>

      <iframe
        style={styles.formFrame}
        src="https://docs.google.com/forms/d/e/1FAIpQLSdb1TIbvXU0XNkjI6TXRNfgv4GGXqcqq3mq7NsHeGZroqaLQw/viewform?embedded=true"
        title="Off the Curriculum Enrolment Form"
      >
        Loading form...
      </iframe>
    </div>
  );
};

export default Enrol;