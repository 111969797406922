import React from 'react';
import { FaExternalLinkAlt, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { FaInstagram, FaTiktok, FaGraduationCap, FaHandHoldingHeart } from 'react-icons/fa6';
import { GoHistory } from "react-icons/go";
import { Link } from 'react-router-dom';
import { FaUsers, FaBookReader, FaYoutube, } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
const AboutPage = () => {
  const navigate = useNavigate();
  const handleMouseEnter = (e) => {
    e.currentTarget.style.boxShadow = '0 10px 15px rgba(0, 0, 0, 0.1)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
  };
  const handleCardClick = (path) => {
    navigate(path);
  };
  const styles = {
    container: {
      maxWidth: '1024px',
      margin: '0 auto',
      padding: '24px',
      paddingTop:'60px'
    },
    mainSection: {
      backgroundColor: 'white',
      borderRadius: '12px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      padding: '32px',
      marginBottom: '32px',
    },
    header: {
      color: '#1F484C',
      fontWeight: '600',
      marginBottom: '16px',
    },
    subHeader: {
      color: '#4b5563',
      marginBottom: '24px',
    },
    missionBox: {
      backgroundColor: '#E3F4F3',
      padding: '40px',
      borderRadius: '12px',
      marginBottom: '48px',
      position: 'relative',
      textAlign: 'center',
    },
    missionIconContainer: {
      position: 'absolute',
      top: '-24px',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: '#1F484C',
      borderRadius: '50%',
      width: '48px',
      height: '48px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    missionText: {
      color: '#1F484C',
      fontStyle: 'italic',
      lineHeight: '1.6',
      maxWidth: '800px',
      margin: '0 auto',
    },
    sectionContainer: {
      marginBottom: '48px',
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      marginBottom: '24px',
    },
    sectionIconContainer: {
      backgroundColor: '#E3F4F3',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    sectionTitle: {
      color: '#1F484C',
      fontWeight: '600',
    },
    paragraph: {
      color: '#4b5563',
      lineHeight: '1.8',
      marginBottom: '24px',
    },
    quickLinks: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '12px',
      marginTop: '24px',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      padding: '8px 16px',
      backgroundColor: '#E3F4F3',
      color: '#1F484C',
      borderRadius: '8px',
      textDecoration: 'none',
      fontWeight: '500',
      transition: 'background-color 0.2s',
    },
    offerGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
      gap: '24px',
      marginTop: '24px',
    },
    offerCard: {
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      padding: '24px',
      transition: 'transform 0.2s',
    },
    highlightCard: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '24px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '32px'
    },
    cardContent: {
      flex: 1,
    },
    cardFeatures: {
      marginTop: '16px',
      paddingTop: '16px',
      borderTop: '1px solid #e5e7eb',
    },
    featureList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    featureItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginBottom: '8px',
      color: '#4b5563',
      fontSize: '0.9rem',
    },
    learnMore: {
      marginTop: '16px',
      color: '#1F484C',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    iconContainer: {
      width: '48px',
      height: '48px',
      borderRadius: '50%',
      backgroundColor: '#E3F4F3',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '16px',
    },
    icon: {
      fontSize: '24px',
      color: '#1F484C',
    },
    highlightsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
      gap: '24px',
    },
    footer: {
      marginTop: window.innerWidth < 768 ? '24px' : '32px',
      textAlign: 'center',
      fontSize: window.innerWidth < 768 ? '12px' : '14px',
      color: '#6b7280',
      padding: window.innerWidth < 768 ? '0 10px' : '0',
    },
    contactSection: {
      backgroundColor: 'white',
      borderRadius: '12px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      padding: '32px',
      marginTop: '32px',
      textAlign: 'center',
    },
    contactText: {
      color: '#4b5563',
      fontSize: '1.1rem',
      marginBottom: '20px',
      lineHeight: '1.6',
    },
    contactLink: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: '8px',
      padding: '12px 24px',
      backgroundColor: '#E3F4F3',
      color: '#1F484C',
      borderRadius: '8px',
      textDecoration: 'none',
      fontSize: '1.1rem',
      fontWeight: '500',
      transition: 'all 0.2s ease',
    },
  };
  const highlights = [
    {
      title: "Communities",
      description: "Connect, learn, and grow with like-minded individuals in our thriving community platform.",
      Icon: FaUsers,
      path: "/communities",
      features: [
        "Create and join study groups",
        "Discuss topics with peers",
        "Share resources and insights",
        "Organize virtual meetups",
        "Build your academic network"
      ]
    },
    {
      title: "Mentoring",
      description: "Get guided by industry experts and leading academics in your field of interest.",
      Icon: FaBookReader,
      path: "/mentors",
      features: [
        "1-on-1 mentoring sessions",
        "Expert guidance",
        "Career development advice",
        "Academic support",
        "Regular feedback sessions"
      ]
    },
    {
      title: "Explore",
      description: "Dive into a world of knowledge with our curated video content from experts across various fields.",
      Icon: FaYoutube,
      path: "/explore",
      features: [
        "Climate change insights",
        "Law and politics",
        "Science and technology",
        "Arts and humanities",
        "Interactive workshops"
      ]
    }
  ];
  
  

  return (
    <div style={styles.container}>
      <div style={styles.mainSection}>
        <h1 style={styles.header}>Off the Curriculum</h1>
        <p style={styles.subHeader}>
          Empowering young people through education, mentoring, and community building.
        </p>

        <div style={styles.quickLinks}>
          <a href="#" style={styles.link}>
            <FaExternalLinkAlt /> Charity Register
          </a>
          <a href="#" style={styles.link}>
            <FaTwitter /> Twitter
          </a>
          <a href="#" style={styles.link}>
            <FaLinkedin /> LinkedIn
          </a>
          <a href="#" style={styles.link}>
            <FaInstagram /> Instagram
          </a>
          <a href="#" style={styles.link}>
            <FaTiktok /> TikTok
          </a>
        </div>
      </div>

      <div style={styles.mainSection}>
        <div style={styles.missionBox}>
          <div style={styles.missionIconContainer}>
            <FaHandHoldingHeart color="white" size={24} />
          </div>
          <p style={styles.missionText}>
            "To bring empowerment and opportunity to every genius* in the world. If you have a brain you are a genius!"
          </p>
        </div>

        <div style={styles.sectionContainer}>
          <div style={styles.sectionHeader}>
            <div style={styles.sectionIconContainer}>
              <GoHistory size={20} color="#1F484C" />
            </div>
            <h2 style={styles.sectionTitle}>Our Story</h2>
          </div>
          <p style={styles.paragraph}>
            Off the Curriculum is an educational charity founded in 2020 during the first COVID-19 lockdown in Brixton by Dr. Joseph Michael Levermore. Born from firsthand experience of growing up in a working-class single-parent family in inner-city Birmingham, the charity focuses on empowering young people from low-socioeconomic backgrounds.
          </p>
          <p style={styles.paragraph}>
            We believe in the power of positive role models to change perceptions and open doors to new opportunities. Our mission is carried out through comprehensive educational experiences that combine guided, instructed, and self-directed learning.
          </p>
        </div>
      </div>
      <div style={styles.highlightsContainer}>
        {highlights.map((highlight, index) => (
          <div 
            key={index}
            style={styles.highlightCard}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleCardClick(highlight.path)}
          >
            <div style={styles.cardContent}>
              <div style={styles.iconContainer}>
                <highlight.Icon style={styles.icon} />
              </div>
              <h3 style={styles.cardTitle}>{highlight.title}</h3>
              <p style={styles.cardDescription}>{highlight.description}</p>
              
              <div style={styles.cardFeatures}>
                <ul style={styles.featureList}>
                  {highlight.features.map((feature, idx) => (
                    <li key={idx} style={styles.featureItem}>
                      <span style={{color: '#1F484C'}}>•</span> {feature}
                    </li>
                  ))}
                </ul>
              </div>
              
              <div style={styles.learnMore}>
                Learn more <span>→</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={styles.contactSection}>
        <h2 style={styles.sectionTitle}>Have Questions?</h2>
        <p style={styles.contactText}>
          Your message will help us create positive change. We'll respond within 24-48 hours.
        </p>
        <Link 
          to="/contactus"
          style={styles.contactLink}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = '#bfdbfe';
            e.currentTarget.style.transform = 'translateY(-2px)';
            e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = '#E3F4F3';
            e.currentTarget.style.transform = 'none';
            e.currentTarget.style.boxShadow = 'none';
          }}
        >
          <FaHandHoldingHeart size={20} />
          Get in Touch
        </Link>
      </div>
    </div>
  );
};

export default AboutPage;