import React, { useState } from "react";
import { motion } from "framer-motion"; 
const DonateCard = ({ imageSrc, amount, description, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  const cardStyle = {
    backgroundColor: 'white',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: isHovered 
      ? '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)'
      : '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    transform: isHovered ? 'translateY(-8px)' : 'none',
    flex: '1 1 300px', // Changed this line
    maxWidth: '350px',
    minWidth: '280px', // Added min-width
    margin: '10px',
    textDecoration: 'none',
    display: 'block',
  };

  const imageStyle = {
    width: '100%',
    height: '350px',
    objectFit: 'cover',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
  };

  const contentStyle = {
    padding: '1.5rem',
  };

  const amountStyle = {
    fontSize: '24px',
    fontWeight: '600',
    color: '#1F484C',
    marginBottom: '8px',
  };

  const descriptionStyle = {
    fontSize: '16px',
    color: '#4B5563',
    lineHeight: '1.5',
  };

  return (
    <a 
      href={link}
      style={cardStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={imageSrc} alt={`Donate ${amount}`} style={imageStyle} />
      <div style={contentStyle}>
        <h3 style={amountStyle}>Donate {amount}</h3>
        <p style={descriptionStyle}>{description}</p>
      </div>
    </a>
  );
};

const Donate = () => {
  const [activeTab, setActiveTab] = useState('once');

  const containerStyle = {
    maxWidth: '1400px',
    margin: '0 auto',
    padding: '40px 20px',
    width: '100%', // Added this
  };

  const headerStyle = {
    textAlign: 'center',
    marginBottom: '40px',
  };

  const titleStyle = {
    fontSize: '36px',
    fontWeight: '700',
    color: '#1F484C',
    marginBottom: '16px',
  };

  const cardsContainerStyle = {
    minHeight: '600px', // Changed from fixed height
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start', // Added this
    gap: '24px',
    padding: '20px 0',
    width: '100%', // Added this
  };

  const amazonStyle = {
    textAlign: 'center',
    marginTop: '40px',
    padding: '24px',
    backgroundColor: '#F3F4F6',
    borderRadius: '12px',
    fontSize: '16px',
    color: '#4B5563',
  };

  const linkStyle = {
    color: '#1F484C',
    textDecoration: 'underline',
    fontWeight: '500',
  };

  const donations = [
    {
      amount: "£10",
      description: "Provides classroom stationary such as whiteboards & flashcards for in-person learning.",
      imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fdonation%2Fimg1.webp?alt=media&token=536958be-46aa-48db-973d-d1d931254246`,
      link: "https://link.justgiving.com/..."
    },
    {
      amount: "£20",
      description: "Prints t-shirts for our seminar volunteers and certificates for our students.",
      imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fdonation%2Fimg2.webp?alt=media&token=35a6dd46-f9dc-4153-a994-fc1700b17987`,
      link: "https://link.justgiving.com/..."
    },
    {
      amount: "£50",
      description: "Recruits additional volunteers and camera rental to facilitate the production of EDUCO content.",
      imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fdonation%2Fimg3.webp?alt=media&token=5b3b257d-3033-4ca7-9dbb-b8b3e34fa608`,
      link: "https://link.justgiving.com/..."
    }
  ];
  const summaryContainerStyle = {
    textAlign: 'center',
    maxWidth: '800px',
    margin: '0 auto 60px',
    padding: '0 20px',
  };
  
  const summaryTextStyle = {
    fontSize: '18px',
    lineHeight: '1.6',
    color: '#4B5563',
    marginBottom: '30px',
  };
  
  const benefitsContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    backgroundColor: 'white',
    padding: '24px',
    borderRadius: '12px',
    marginTop: '20px',
  };
  
  const benefitItemStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '16px',
    color: '#374151',
  };
  
  const checkmarkStyle = {
    color: '#10B981', // Green color for checkmark
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <header style={headerStyle}>
        <h1 style={titleStyle}>Support Our Mission</h1>
      </header>
      <div style={summaryContainerStyle}>
      <p style={summaryTextStyle}>
        Your support is vital in helping us make education accessible to everyone. 
        As donations are our primary source of revenue, every contribution directly impacts 
        our ability to provide quality education and resources to those who need it most.
      </p>
      <div style={benefitsContainerStyle}>
        <span style={{ fontWeight: '600', marginBottom: '8px' }}>Your donation helps with:</span>
        {[
          'Learning materials',
          'Making education accessible to everyone',
          'Providing certifications',
          'Running the organisation',
          'Other miscellaneous work'
        ].map((benefit, index) => (
          <div key={index} style={benefitItemStyle}>
            <span style={checkmarkStyle}>✓</span>
            {benefit}
          </div>
        ))}
      </div>
    </div>

      <motion.div 
        style={cardsContainerStyle}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {donations.map((donation, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2 }}
          >
            <DonateCard {...donation} />
          </motion.div>
        ))}
      </motion.div>

      <div style={amazonStyle}>
        Or alternatively, support us while you shop by registering Off the Curriculum with your{' '}
        <a 
          href="https://smile.amazon.co.uk/ch/1196701-0"
          style={linkStyle}
        >
          Amazon Smile Account
        </a>
      </div>
    </div>
  );
};

export default Donate;
