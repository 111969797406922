import React, { useState, useEffect, useCallback, useRef } from "react";
import Modal from "./Modal/Modal";
import { functions } from "../../../Firebase/firebaseConfig";
import { httpsCallable } from "firebase/functions";
import useAuth from "../../../Firebase/Auth";
import { emailsHasher } from "../../../Firebase/educoFunctions";
import StandardButtonContainer from "../../../Components/Common/Buttons/StandardButtons/StandardButtons";
import EllipsisText from "../../../Components/Common/EllipsisText/ellipsisText";
// Custom debounce function
import BottomLoader from "../../../Components/Common/Loading/BottomLoaderComponent";

const MentorsExplorePage = () => {
  const [initialMentorData, setInitialMentorData] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [dataLength, setDataLength] = useState([]);
  const [interest, setInterest] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("All");
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const currentUser = useAuth();
  const isMounted = useRef(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(windowWidth <= 400);

  useEffect(() => {
    const handleResize = () => {  
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);


  // Safe setState wrapper
  const safeSetState = (setter) => (...args) => {
    if (isMounted.current) {
      setter(...args);
    }
  };

  // Base fetch recommended mentors function
  useEffect(() => {
    const fetchInitialMentors = async () => {
      if (!currentUser?.email) return;
      
      try {
        safeSetState(setLoading)(true);
        const hashedEmail = emailsHasher(currentUser.email);
        const getRecommendedMentors = httpsCallable(functions, "get_mentor_list");
        const result = await getRecommendedMentors({ userId: hashedEmail });
        if (result.data?.success === true) {
          setMentors(result.data.mentors);
          setInitialMentorData(result.data.mentors);
          setDataLength(result.data.mentor_length);
          safeSetState(setInterest)(result.data.topicInterest);
          safeSetState(setSelectedSubject)("All");
          setLoading(false)
        }
      } catch (error) {
        //console.error("Error fetching recommended mentors:", error);
      } finally {
        if (isMounted.current) {
          safeSetState(setLoading)(false);
        }
      }
    };
  
    fetchInitialMentors();
  }, [currentUser?.email]); // Only depend on email changes

  const fetchMentorsBySubject = async (subject) => {
    try {
      setLoading(true);
      const getMentorsBySubject = httpsCallable(functions, "get_mentors_by_subject");
      const subjectToSend = subject === "All" ? subject : subject.toLowerCase();
      //console.log(subjectToSend)
      const result = await getMentorsBySubject({
        data: {
          subject: subjectToSend
        }
      });
      //console.log('result', result.data)
      if (result.data?.status === "success") {
        setMentors(result.data.mentors);
        setDataLength(result.data.total);
      }
    } catch (error) {
      //console.error("Error fetching mentors:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = async (e) => {
    const newCategory = e.target.value;
    setSelectedSubject(newCategory); // Update the selected subject state
  
    if (newCategory === "All") {
      //console.log('Switching to All mentors');
      // Use cached initial mentors
      setMentors(initialMentorData);
      setDataLength(initialMentorData.length);
    } else {
      //console.log('Fetching mentors for:', newCategory);
      // Fetch filtered mentors
      await fetchMentorsBySubject(newCategory);
    }
  };
  

  const handleOpenModal = (mentor) => {
    setSelectedMentor(mentor);
    setShowModal(true);
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMentor(null);
  };
  const displaySubject = 
  selectedSubject === "stem" ? "STEM" : 
  selectedSubject === "humanities" ? "Humanities" : 
  "All";
  
  //console.log(selectedSubject)

  const containerStyle = {
    width: "100%",
    marginBottom: '50px',
    boxSizing: "border-box",
    "@media (max-width: 768px)": {
      padding: "16px"
    },
    "@media (max-width: 400px)": {
      padding: "12px"
    }
  };
  
  // Update the mentors container grid
  const mentorsContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(600px, 1fr))",
    gap: "24px",
    width: "100%",
    "@media (max-width: 768px)": {
      gridTemplateColumns: "1fr",
      gap: "16px"
    },
    "@media (max-width: 400px)": {
      gap: "0px",
      width: 75
    }
  };
  
  const getMentorsContainerStyle = (width) => {
    const baseStyle = {
      display: "grid",
      width: "100%",
      gap: "24px",
      paddingTop: "10px",
      paddingBottom: "10px"
    };
  
    if (width <= 768) { // Mobile
      return {
        ...baseStyle,
        gridTemplateColumns: "1fr",
        maxWidth: "490px",
        margin: "0 auto", // Ensure center alignment
        padding: "0px"
      };
    } else if (width <= 900) { // Tablet
      return {
        ...baseStyle,
        gridTemplateColumns: "repeat(2, 1fr)",
        maxWidth: "800px", // Add maxWidth instead of percentage width
        width: "100%", // Use full width within maxWidth constraint
        padding: "0px"
      };
    } else if (width <= 1620) { // Tablet
      return {
        ...baseStyle,
        gridTemplateColumns: "repeat(2, 1fr)",
        maxWidth: "1000px", // Add maxWidth instead of percentage width
        margin: "0 auto",
        width: "100%",
        padding: "0px"
      }; 
    } else { // Desktop
      return {
        ...baseStyle,
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: '15px',
        margin: "0 auto", // Ensure center alignment
      };
    }
  };
  
  const getMentorCardStyle = (width) => {
    const baseStyle = {
      display: "flex",
      backgroundColor: "#fff",
      borderRadius: "20px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.05)",
      border: "1px solid #f0f0f0",
      transition: "all 0.3s ease-in-out",
      cursor: "pointer",
      width: "100%"
    };
  
    if (width <= 450) {
      return {
        ...baseStyle,
        flexDirection: "column",
        padding: "16px",
        alignItems: "center",
        width: "85%"
      };
    } else if (width <= 768) {
      return {
        ...baseStyle,
        flexDirection: "column",
        padding: "16px",
        alignItems: "center",
        width: "90%"
      };
    } else if (width <= 960) { // Tablet
      return {
        ...baseStyle,
        flexDirection: "column",
        padding: "16px",
        alignItems: "center",
        width: "300px"
      }; 
    } else if (width <= 1491) { // Tablet
      return {
        ...baseStyle,
        flexDirection: "column",
        padding: "16px",
        alignItems: "center",
        width: "400px"
      }; 
    } else {
      return {
        ...baseStyle,
        flexDirection: "row",
        padding: "24px",
        gap: "24px",
        width: "450px"
      };
    }
  };
  
  const getProfilePicStyle = (width) => {
    if (width <= 768) {
      return {
        width: "200px",
        height: "200px",
        borderRadius: "16px",
        objectFit: "cover",
        marginBottom: "16px"
      };
    } else if (width <= 1024) { // Tablet
      return {
        width: "160px",
        height: "160px",
        borderRadius: "16px",
        objectFit: "cover",
        flexShrink: 0
      }; 
    } else if (width <= 1200) { // Tablet
      return {
        width: "180px",
        height: "180px",
        borderRadius: "16px",
        objectFit: "cover",
        flexShrink: 0
      }; 
    }else {
      return {
        width: "120px",
        height: "160px",
        borderRadius: "16px",
        objectFit: "cover",
        flexShrink: 0
      };
    }
  };

  const getContentStyle = (width) => {
    const baseStyle = {
      display: "flex",
      flexDirection: "column",
      flex: 1
    };
  
    if (width <= 700) {
      return {
        ...baseStyle,
        alignItems: "center",
        textAlign: "center",
        width: "100%"
      };
    } else {
      return {
        ...baseStyle,
        alignItems: "flex-start",
        textAlign: "left"
      };
    }
  };
  
  
  const statusContainer = {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    color: "#718096",
    fontSize: "14px",
    marginBottom: "12px",
    flexWrap: "wrap",
    "@media (max-width: 400px)": {
      justifyContent: "center"
    }
  };

  
  // Update footer style
  const footerStyle = (width) => ({
    display: "flex",
    justifyContent: width <= 700 ? "center" : "space-between",
    alignItems: "center",
    marginTop: "auto",
    paddingTop: "16px",
    borderTop: "1px solid #EDF2F7",
    width: "100%",
    flexDirection: width <= 700 ? "column" : "row",
    gap: width <= 700 ? "12px" : "8px"
  });
  
  // Update filters container
  const filtersContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    marginBottom: "20px",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      gap: "8px"
    }
  };
  
  // Update filter style
  const filterStyle = {
    padding: "10px 20px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    cursor: "pointer",
    "@media (max-width: 768px)": {
      padding: "8px 16px"
    },
    "@media (max-width: 400px)": {
      width: "100%",
      boxSizing: "border-box"
    }
  };
  
  const headerStyle = {
    marginBottom: "20px",
    borderRadius: "10px",
  };


  const mentorNameStyle = {
    fontSize: "24px",
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: "4px"
  };

  const languageStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    marginTop: "8px",
    marginBottom: "8px"
  };
  
  const languageTag = {
    padding: "6px 12px",
    backgroundColor: "#FDF5E8",
    borderRadius: "20px",
    fontSize: "14px",
    color: "#E79B18",
    fontWeight: "500",
    display: "inline-flex",
    alignItems: "center",
    border: '1px solid #E79B18',
  };
  
  const bioStyle = {
    fontSize: "15px",
    lineHeight: "1.5",
    color: "#4A5568",
    marginBottom: "16px",
    flex: "1"
  };

  
const formatLanguage = (specialty) => {
  const matches = specialty.match(/(.*?)\s*\((.*?)\)/);
  if (matches) {
    const [, spec, level] = matches;
    return (
      <span className="languageStyle languageTag">
        <strong>{spec.trim()}</strong>
        <span className="languageTag">({spec.trim()})</span>
      </span>
    );
  }
  return <span className="languageStyle languageTag">{specialty}</span>;
};

  return (
    <div style={containerStyle}>
          <header style={headerStyle}>
            <h1>Find an online tutor to help you study</h1>
            <p>
              Get ready to dive deep into your favorite subjects with our expert tutors. 
              From STEM fields to Humanities, our mentors are here to guide you.
              <span style={{color: 'red'}}> Please note you are only able to make one mentor request at any one time!</span>
            </p>
          </header>
          <div style={filtersContainerStyle}>
            <div style={{
              ...filterStyle,
              ...(isMobile && {
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              })
            }}>
              Category:
              <select 
                value={selectedSubject}
                onChange={handleCategoryChange}
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                  ...(isMobile && {
                    marginLeft: "auto",
                    minWidth: "150px"
                  })
                }}>
                <option value="All">All</option>
                <option value="stem">STEM</option>
                <option value="humanities">Humanities</option>
              </select>
            </div>
          </div>

          <h2>
          {displaySubject} mentors available: {dataLength} 
        </h2>
          {loading ? (
            <>
            <BottomLoader 
              isLoading={true}
              message="Loading your mentor content..."
              backgroundColor={"rgba(255, 255, 255, 0.95)"}
              textColor={"#FFFFFF"}
            />
          </>
        ) : (
          <>
          <div style={getMentorsContainerStyle(windowWidth)}>
            {mentors.map((mentor) => (
              <div
                key={mentor.id}
                style={getMentorCardStyle(windowWidth)}
                onMouseEnter={(e) => {
                  if (windowWidth > 768) {
                    Object.assign(e.currentTarget.style, {
                      transform: "translateY(-8px)",
                      boxShadow: "0 12px 20px rgba(0, 0, 0, 0.1)"
                    });
                  }
                }}
                onMouseLeave={(e) => {
                  if (windowWidth > 768) {
                    Object.assign(e.currentTarget.style, {
                      transform: "translateY(0)",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.05)"
                    });
                  }
                }}
                onClick={() => handleOpenModal(mentor)}
              >
                <img
                  src={mentor.profileImage}
                  alt={mentor.name}
                  style={getProfilePicStyle(windowWidth)}
                />
                
                <div style={getContentStyle(windowWidth)}>
                  <div>
                    <h3 style={mentorNameStyle}>{mentor.name}</h3>
                    <div style={{ 
                      display: "flex", 
                      gap: "8px", 
                      color: "#718096",
                      fontSize: "14px",
                      alignItems: "center",
                      justifyContent: windowWidth <= 700 ? "center" : "flex-start"
                    }}>
                      <span>{mentor.activeStudents} active students</span>
                      <span>•</span>
                      <span>{mentor.lessons} lessons</span>
                    </div>
                  </div>
          
                  <div style={languageStyle}>
                    {mentor.specialties?.split(';')
                      .map(specialty => specialty.trim())
                      .map((specialty, index) => (
                        <div style={languageTag} key={index}>
                          {formatLanguage(specialty)}
                        </div>
                      ))}
                  </div>
          
                  <div style={bioStyle}>
                    <EllipsisText 
                      text={mentor.bio || ''} 
                      tag="p" 
                      maxLength={35}
                    />
                  </div>
          
                  <div style={footerStyle(windowWidth)}>
                    <span style={{ 
                      color: "#718096",
                      fontSize: "14px",
                      fontWeight: "500"
                    }}>
                      Maximum session length: 50-min lesson
                    </span>
                    <StandardButtonContainer>
                      <StandardButtonContainer.ButtonTwo
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenModal(mentor);
                        }}
                      >
                        Learn More
                      </StandardButtonContainer.ButtonTwo>
                    </StandardButtonContainer>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {showModal && selectedMentor && (
            <>
            {/*console.log('here', selectedMentor.id)*/}
            <Modal
              show={showModal}
              onClose={handleCloseModal}
              mentorEmail={selectedMentor.email}
              mentorId={selectedMentor.id}
              title={selectedMentor.name}
              description={selectedMentor.bio}
              specialties={selectedMentor.specialties?.split(';').map(s => s.trim()) || []}
              languages={selectedMentor.languages?.split(';').map(l => l.trim()) || []}
              methods={["Online"]}
              imageUrl={selectedMentor.profileImage}
            />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MentorsExplorePage;