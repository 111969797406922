export default {
  id: 'activeLearning',
    title: "Active learning and improving your revision style",
    hero_image: "path_to_hero_image.jpg",
    author: {
      name: "Nicole Curtis",
      image: '',
      bio: "I take care of all Marketing and Communications at Off the Curriculum. I was born in London but moved to Spain as a child, growing up there until university. Currently, I am an MSc student reading Psychology and Neuroscience of Mental Health at King’s College London. I work in Child and Adolescent Mental Health and Addiction Rehabilitation. With a passion for education, I jumped at the opportunity to be a part of OTC. Curious by nature, I am passionate about mental health and wellbeing, the creative and performing arts, food, travel, and culture.",
      social: {
        twitter: "https://twitter.com/otclambeth/",
        linkedin: "https://www.linkedin.com/in/nicole-l-aa60301b5",
        instagram: "https://www.instagram.com/offthecurriculumlondon/"
      }
    },
    metadata: {
      date: "2023-02-06",
      readTime: "5 minutes",
      category: "Empowerment"
    },
    content: [
      {
        type: "heading",
        content: "Introduction"
      },
      {
        type: "paragraph",
        content: "Active learning techniques are methods of learning that engage students in the learning process through activities that require active participation. Research has shown that active learning is more effective than passive learning techniques, such as simply reading or highlighting material (Dunlosky et al., 2013). In this article, we will explore some active learning techniques and how to incorporate them into your revision schedule."
      },
      {
        type: "heading",
        content: "Self-quizzing"
      },
      {
        type: "paragraph",
        content: `Self-quizzing is a simple yet effective active learning technique that involves testing yourself on the material you are trying to learn. This technique has been shown to be particularly effective for improving long-term memory retention (Karpicke & Roediger, 2008). To incorporate self-quizzing into your revision schedule, set aside time to create questions based on the material you are trying to learn, and then test yourself on these questions regularly. `
      },
      {
        type: "heading",
        content: "Summarising"
      },
      {
        type: "paragraph",
        content: "Summarising involves condensing the material you are trying to learn into a shorter, more concise format. This technique can help you to identify the most important information and improve your understanding of the material. To incorporate summarising into your revision schedule, set aside time to create summaries of the material you are trying to learn, and then review these summaries regularly."
      },
      {
        type: "heading",
        content: "Teaching others"
      },
      {
        type: "paragraph",
        content: "Teaching others is a highly effective active learning technique that involves explaining the material you are trying to learn to someone else. This technique can help to solidify your understanding of the material and improve your ability to recall it. To incorporate teaching into your revision schedule, find a study partner or group and take turns explaining the material to each other."
      },
      {
        type: "heading",
        content: "Group discussion"
      },
      {
        type: "paragraph",
        content: "Group discussion is a collaborative active learning technique that involves discussing the material you are trying to learn with others. This technique can help you to identify gaps in your knowledge and gain a deeper understanding of the material through the perspectives of others. To incorporate group discussion into your revision schedule, find a study group and set aside time to discuss the material you are trying to learn."
      },
      {
        type: "heading",
        content: "Mind mapping"
      },
      {
        type: "paragraph",
        content: "Mind mapping is a visual active learning technique that involves creating a visual representation of the material you are trying to learn. This technique can help you to identify connections between different concepts and improve your ability to recall the material. To incorporate mind mapping into your revision schedule, set aside time to create a mind map of the material you are trying to learn, and then review this mind map regularly."
      },
      {
        type: "paragraph",
        content: "In conclusion, incorporating active learning techniques into your revision schedule can help to improve your retention of the material and enhance your overall understanding of the subject. By incorporating techniques such as self-quizzing, summarising, teaching others, group discussion, and mind mapping into your revision schedule, you can take an active role in your own learning and achieve better results."
      },
      {/*{
        type: "image",
        url: "image_url",
        caption: "Image caption"
      },*/}
      // Add more content sections as needed
    ],
    relatedArticles: [
      {
        title: "Mindfulness",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Fmindfulness.webp?alt=media&token=f8585110-94e6-42ea-8a3b-ad1979e436d4",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Mindfulness"
      },
      {
        title: "Learning to Learn from home",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Learningtolearn"
      }
    ]
  };