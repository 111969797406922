import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const ResourceLibrary = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isDesktop = windowWidth > 1024;
  const isTablet = windowWidth <= 1024;

  const styles = {
    container: {
      display: 'flex',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: isDesktop ? '20px' : '16px',
      gap: isDesktop ? '40px' : '24px',
      flexDirection: isDesktop ? 'row' : 'column',
    },
    leftSection: {
      width: isDesktop ? '300px' : '100%',
      position: isDesktop ? 'sticky' : 'static',
      top: isDesktop ? '20px' : '0',
      height: isDesktop ? 'fit-content' : 'auto',
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#4b5563',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      marginBottom: '20px',
      fontSize: '1em',
      padding: '8px 12px',
      borderRadius: '4px',
      transition: 'background-color 0.2s ease',
      '&:hover': {
        backgroundColor: '#f3f4f6',
      }
    },
    pageTitle: {
      fontSize: isDesktop ? '2.5em' : '2em',
      fontWeight: '600',
      color: '#1a1a1a',
      marginBottom: '15px',
      letterSpacing: '-0.02em',
    },
    pageDescription: {
      fontSize: '1.1em',
      color: '#4b5563',
      lineHeight: '1.6',
      marginBottom: '30px',
    },
    resourcesGrid: {
      display: 'grid',
      gridTemplateColumns: isDesktop ? 'repeat(2, 1fr)' : '1fr',
      gap: '24px',
      flex: 1,
    },
    resourceCard: {
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #e5e7eb',
      padding: '24px',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      display: 'flex',
      flexDirection: 'column',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
      }
    },
    imageContainer: {
      width: '100%',
      height: '200px',
      marginBottom: '16px',
      borderRadius: '8px',
      overflow: 'hidden',
      backgroundColor: 'white',
    },
    image: {
      width: '100%',
      height: '200px',
      objectFit: 'cover',
      borderRadius: '8px',
      marginBottom: '10px',
    },
    resourceTitle: {
      fontSize: '1.4em',
      fontWeight: '600',
      color: '#111827',
      marginBottom: '12px',
    },
    resourceDescription: {
      fontSize: '1em',
      color: '#4b5563',
      lineHeight: '1.6',
      flex: 1,
    },
  };

  const resources = [
    {
      title: 'Mental health with Nuture With',
      description: 'Unlock your Potential with well-being. Nurture With create personalised well-being with our holistic frameworks and engaging journaling process. Proactively prevent mental health challenges and design a life that meets your unique desires, needs and goals.',
      image: 'https://images.squarespace-cdn.com/content/v1/6527b7512313c84dda3fd507/4ad17eb5-8611-4507-aef8-269a881ca232/Social+Media+NW+%285%29.png?format=1500w'
    },
    {
      title: 'Food security with The Felix Project',
      description: `With the cost of living crisis the food hunger is regularly experienced across the United Kingdom. The Felix Project collects fresh, nutritious food that cannot be sold. We deliver this surplus food to charities and schools so they can provide healthy meals and help the most vulnerable in our society.`,
      image: 'https://www.greencore.com/app/uploads/2018/06/Felix-Project-Logo.png'
    },
    {
        title: 'Access to Sport',
        description: 'The Access to Sports project is a community led, creative & innovative sports development charity that works across the London boroughs of Islington, Haringey & Hackney',
        image: 'https://accesstosports.org.uk/theme/images/logos/A2S-retina.png'
      },
      {
        title: 'Southbank Sounds',
        description: `The Sound Bank is a creative hub based within a newly renovated South London ex- Barclays building. As part of our community give back, they run a host of music related events working with young people in NEED.`,
        image: 'https://careerhub.ldnapprenticeships.com/servlet/rtaImage?eid=a2B3X00000D5Dq1&feoid=00N3X00000Lt6d6&refid=0EM3X000007g1HD'
      },
      {
        title: 'Solace - Domestic abuse',
        description: 'Solace Women’s Aid offers free advice and support to women and children in London to build safe and strong lives. Futures free from male abuse and violence.',
        image: 'https://images.squarespace-cdn.com/content/v1/5f6a71b620b6aa40a963641d/1610137641405-VUQA14OTDGYYT1JWLIT4/solace.jpg'
      },
      {
        title: 'Via Org',
        description: `We are Via: a charity offering free, confidential support to adults and young people with issues around alcohol and drug use. We’re part of a journey, and everyone’s journey is different.`,
        image: 'https://www.viaorg.uk/wp-content/uploads/2023/04/logo-via.png'
      },
      {
        title: 'Anti-bully alliance',
        description: 'Bullying exists online, at school and in the wider community.  We’re here to help those who suffer from bullying, but also to help their families, their teacher’s and their friends. But we also want to help bullies to change, because no one bullies because they are happy...',
        image: 'https://actagainstbullying.org/wp-content/uploads/2023/11/ABA_MEMBER_LOGO_RGB_BLUE.png'
      },
      {
        title: 'Southbank Sounds',
        description: `The Sound Bank is a creative hub based within a newly renovated South London ex- Barclays building. As part of our community give back, they run a host of music related events working with young people in NEED.`,
        image: 'https://careerhub.ldnapprenticeships.com/servlet/rtaImage?eid=a2B3X00000D5Dq1&feoid=00N3X00000Lt6d6&refid=0EM3X000007g1HD'
      }
  ];

  return (
    <div style={styles.container}>
      <div style={styles.leftSection}>
        <button 
          onClick={() => navigate('/statements')} 
          style={styles.backButton}
        >
          <FaArrowLeft /> Back to Statements
        </button>

        <h1 style={styles.pageTitle}>Resource Library</h1>
        <p style={styles.pageDescription}>
          Welcome to Off the Curriculum's Resource Library. We understand you may need support outside of what we can offer. 
          That's why we've curated a list of trusted external organisations - which are available to support you.
        </p>
      </div>

      <div style={styles.resourcesGrid}>
        {resources.map((resource, index) => (
          <div key={index} style={styles.resourceCard}>
            <div style={styles.imageContainer}>
              <img 
                src={resource.image} 
                alt={resource.title} 
                style={styles.image}
              />
            </div>
            <h2 style={styles.resourceTitle}>{resource.title}</h2>
            <p style={styles.resourceDescription}>{resource.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResourceLibrary;