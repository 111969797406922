import React, { useState } from 'react';
import Message from './MessageComponent';
import { FaBrain, FaSearch, FaMicrophone, FaImage, FaSmile, FaLanguage, FaThumbsUp } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';


const MentorChat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [message, setMessage] = useState('');
  
  const mentors = [
    { id: 1, name: "Dr. Sarah Chen", status: "Active now", lastMessage: "Messages and calls are secured", timeAgo: "2h" },
    { id: 2, name: "Prof. James Wilson", status: "Active now", lastMessage: "Messages and calls are secured", timeAgo: "3h" },
    { id: 3, name: "Dr. Maria Garcia", status: "Last active 2h ago", lastMessage: "Messages and calls are secured", timeAgo: "1d" },
    { id: 4, name: "Prof. David Kim", status: "Active now", lastMessage: "Messages and calls are secured", timeAgo: "2d" }
  ];

  const [messages, setMessages] = useState([
    {
      id: 1,
      text: "Hello! How can I help you today?",
      sender: "mentor",
      timestamp: "2:30 PM"
    },
    {
      id: 2,
      text: "Hi! I have some questions about my coursework.",
      sender: "user",
      timestamp: "2:31 PM"
    },
    {
      id: 3,
      text: "Of course! I'd be happy to help. What specific topics are you struggling with?",
      sender: "mentor",
      timestamp: "2:31 PM"
    }
  ]);

  const handleSend = (e) => {
    e.preventDefault();
    if (message.trim()) {
      // Handle sending message
      setMessage('');
    }
  };

  const MessagesList = ({ messages, onEdit, onDelete, onReact }) => {
    return (
      <div style={styles.messagesContainer}>
        {messages.map((message) => (
          <Message
            key={message.id}
            message={message}
            isUser={message.sender === 'user'}
            onEdit={onEdit}
            onDelete={onDelete}
            onReact={onReact}
          />
        ))}
      </div>
    );
  };

  const handleEditMessage = (messageId, newText) => {
    setMessages(messages.map(msg => 
      msg.id === messageId 
        ? { ...msg, text: newText, edited: true } 
        : msg
    ));
  };
  
  const handleDeleteMessage = (messageId) => {
    setMessages(messages.filter(msg => msg.id !== messageId));
  };
  
  const handleReactToMessage = (messageId, reaction, action = 'add') => {
    setMessages(messages.map(msg => {
      if (msg.id === messageId) {
        let updatedReactions = [...(msg.reactions || [])];
        
        if (action === 'remove') {
          updatedReactions = updatedReactions.filter(r => r !== reaction);
        } else if (!updatedReactions.includes(reaction)) {
          updatedReactions.push(reaction);
        }
        
        return {
          ...msg,
          reactions: updatedReactions
        };
      }
      return msg;
    }));
  };

  return (
    <div style={styles.wrapper}>
      <AnimatePresence>
        {!isOpen ? (
          <motion.div
            key="chat-button"
            style={styles.container}
            onClick={() => setIsOpen(true)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ x: 0 }}
            animate={{ x: 0 }}
            exit={{ x: 100 }}
          >
            <FaBrain style={styles.icon} />
            <span style={styles.text}>Chat with Mentor</span>
          </motion.div>
        ) : (
          <motion.div
            key="chat-window"
            style={styles.chatContainer}
            initial={{ x: 400 }}
            animate={{ x: 0 }}
            exit={{ x: 400 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            {!selectedMentor ? (
              // Mentor List View
              <div style={styles.card}>
                <div style={styles.header}>
                  <div style={styles.headerTop}>
                    <h2 style={styles.title}>Chats</h2>
                    <div style={styles.controls}>
                      <button style={styles.controlButton}>
                        <span style={styles.controlText}>⋯</span>
                      </button>
                      <button style={styles.controlButton}>
                        <span style={styles.controlText}>⤢</span>
                      </button>
                      <button 
                        style={styles.controlButton}
                        onClick={() => setIsOpen(false)}
                      >
                        <span style={styles.controlText}>✕</span>
                      </button>
                    </div>
                  </div>
                  
                  <div style={styles.searchContainer}>
                    <FaSearch style={styles.searchIcon} />
                    <input
                      type="text"
                      placeholder="Search Mentors"
                      style={styles.searchInput}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  
                  <div style={styles.filterContainer}>
                    <button style={styles.inboxButton}>
                      Inbox
                    </button>
                  </div>
                </div>

                <div style={styles.mentorList}>
                  {mentors.map((mentor) => (
                    <motion.div
                      key={mentor.id}
                      whileHover={{ backgroundColor: "#f5f5f5" }}
                      style={styles.mentorItem}
                      onClick={() => setSelectedMentor(mentor)}
                    >
                      <div style={styles.avatarContainer}>
                        <div style={styles.avatar}>
                          <FaBrain style={styles.avatarIcon} />
                        </div>
                        <div style={styles.statusDot}></div>
                      </div>
                      <div style={styles.mentorInfo}>
                        <div style={styles.mentorHeader}>
                          <span style={styles.mentorName}>{mentor.name}</span>
                          <span style={styles.timeago}>{mentor.timeAgo}</span>
                        </div>
                        <div style={styles.lastMessage}>{mentor.lastMessage}</div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            ) : (
              // Chat Page View
              <div style={styles.card}>
                <div style={styles.chatHeader}>
                  <div style={styles.chatHeaderContent}>
                    <div style={styles.headerLeft}>
                      <button 
                        style={styles.backButton}
                        onClick={() => setSelectedMentor(null)}
                      >
                        ←
                      </button>
                      <div style={styles.avatarAndInfo}>
                        <div style={styles.avatar}>
                          <FaBrain style={styles.avatarIcon} />
                        </div>
                        <div style={styles.mentorDetails}>
                          <h3 style={styles.chatMentorName}>{selectedMentor.name}</h3>
                        </div>
                      </div>
                    </div>
                    <div style={styles.headerControls}>
                      <button style={styles.iconButton}>-</button>
                      <button 
                        style={styles.iconButton} 
                        onClick={() => {
                          setSelectedMentor(null);
                          setIsOpen(false);
                        }}
                      >
                        ×
                      </button>
                    </div>
                  </div>
                  <div style={styles.statusLine}>
                    <span style={styles.activeStatus}>Active now</span>
                  </div>
                </div>
                    {/* Example message */}
                <div style={styles.messageContainer}>
                  <MessagesList
                    messages={messages}
                    onEdit={handleEditMessage}
                    onDelete={handleDeleteMessage}
                    onReact={handleReactToMessage}
                  />
                </div>
                <form onSubmit={handleSend} style={styles.inputArea}>                  
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Aa"
                  style={styles.chatInput}
                />
                  <div style={styles.inputControls}>
                    <button type="button" style={styles.inputButton}>
                      <FaSmile />
                    </button>
                    <button type="button" style={styles.inputButton}>
                      <FaThumbsUp />
                    </button>
                  </div>
                </form>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const styles = {
  wrapper: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 999,
  },
  container: {
    position: 'fixed',
    bottom: '20px',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#e6f7ff',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    padding: '10px 20px 10px 15px',
    boxShadow: '-2px 2px 10px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    zIndex: 999,
  },
  icon: {
    fontSize: '24px',
    color: '#ff9999',
    marginRight: '10px',
  },
  text: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333',
  },
  chatContainer: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    width: '384px',
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    overflow: 'hidden',
    zIndex: 999,
  },
  card: {
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: '1rem',
    borderBottom: '1px solid #e5e7eb',
  },
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    margin: 0,
  },
  controls: {
    display: 'flex',
    gap: '0.5rem',
  },
  controlButton: {
    padding: '0.5rem',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '9999px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controlText: {
    fontSize: '1.25rem',
  },
  searchContainer: {
    position: 'relative',
  },
  searchIcon: {
    position: 'absolute',
    left: '0.75rem',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#9ca3af',
  },
  searchInput: {
    width: '100%',
    paddingLeft: '2.5rem',
    paddingRight: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    backgroundColor: '#f3f4f6',
    border: 'none',
    borderRadius: '9999px',
    outline: 'none',
  },
  filterContainer: {
    marginTop: '0.5rem',
  },
  inboxButton: {
    padding: '0.25rem 1rem',
    backgroundColor: '#e6f7ff',
    color: '#1890ff',
    border: 'none',
    borderRadius: '9999px',
    fontSize: '0.875rem',
    fontWeight: '500',
    cursor: 'pointer',
  },
  mentorList: {
    overflowY: 'auto',
    flex: 1,
  },
  mentorItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.75rem',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  avatarContainer: {
    position: 'relative',
  },
  avatar: {
    width: '48px',
    height: '48px',
    backgroundColor: '#f3f4f6',
    borderRadius: '9999px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarIcon: {
    fontSize: '1.5rem',
    color: '#6b7280',
  },
  statusDot: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '12px',
    height: '12px',
    backgroundColor: '#10b981',
    borderRadius: '9999px',
    border: '2px solid #fff',
  },
  mentorInfo: {
    marginLeft: '0.75rem',
    flex: 1,
  },
  mentorHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mentorName: {
    fontWeight: '500',
  },
  timeago: {
    fontSize: '0.875rem',
    color: '#6b7280',
  },
  lastMessage: {
    fontSize: '0.875rem',
    color: '#6b7280',
  },

  // chat page 
  chatHeader: {
    padding: '12px 16px',
    borderBottom: '1px solid #e4e6eb',
    backgroundColor: 'white',
  },
  chatHeaderContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '4px',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  avatarAndInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  backButton: {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    padding: '8px',
    color: '#65676B',
  },
  chatMentorName: {
    margin: 0,
    fontSize: '16px',
    fontWeight: '600',
  },
  statusLine: {
    paddingLeft: '44px', // Aligns with the content after back button
  },
  activeStatus: {
    fontSize: '13px',
    color: '#65676B',
  },
  headerControls: {
    display: 'flex',
    gap: '4px',
  },
  iconButton: {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    padding: '8px',
    color: '#65676B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  
  mentorDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '12px',
  },
  subtitle: {
    fontSize: '12px',
    color: '#65676B',
  },
  messageContainer: {
    flex: 1,
    overflowY: 'auto',
    padding: '16px',
    backgroundColor: 'white',
  },
  message: {
    display: 'flex',
    marginBottom: '12px',
  },
  messageText: {
    padding: '12px 16px',
    backgroundColor: '#e4e6eb',
    borderRadius: '18px',
    fontSize: '14px',
    maxWidth: '80%',
  },
  inputArea: {
    padding: '12px 16px',
    borderTop: '1px solid #e4e6eb',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  chatInput: {
    flex: 1,
    padding: '8px 12px',
    borderRadius: '20px',
    border: '1px solid #1F484C',
    fontSize: '14px',
    outline: 'none',
    resize: 'none', // Prevent manual resizing
    minHeight: '20px',
    maxHeight: '150px',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    overflowY: 'auto',
    overflowX: 'hidden',
    // Flex properties to vertically center the text
    display: 'flex',
    alignItems: 'center',
    lineHeight: '20px', // Match the line height to the font size for vertical alignment
  },
  inputControls: {
    display: 'flex',
    gap: '8px',
  },
  inputButton: {
    background: 'none',
    border: 'none',
    padding: '8px',
    cursor: 'pointer',
    color: '#1F484C',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default MentorChat;