import React from 'react';
import { RiTwitterXLine } from "react-icons/ri";
import { CiLinkedin } from "react-icons/ci";
import { CiMail } from "react-icons/ci";

const OurTeam = () => {
  const styles = {
    mainContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '2rem',
    },
    header: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '2rem',
      color: '#1a1a1a'
    },
    introduction: {
      fontSize: '1.1rem',
      lineHeight: '1.6',
      textAlign: 'center',
      marginBottom: '3rem',
      color: '#444444'
    },
    trusteesGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '2rem',
      padding: '1rem'
    },
    trusteeCard: {
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      overflow: 'hidden',
      transition: 'transform 0.3s ease',
      cursor: 'pointer'
    },
    imageContainer: {
      width: '100%',
      paddingTop: '100%',
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    image: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)', // Centers the image
      width: '90%',
      height: '90%',
      objectFit: 'cover'
    },
    cardContent: {
      padding: '1.5rem'
    },
    trusteeTitle: {
      fontSize: '1.25rem',
      fontWeight: '600',
      marginBottom: '0.5rem',
      color: '#1a1a1a'
    },
    trusteePosition: {
      fontSize: '1rem',
      color: '#666666',
      marginBottom: '0.25rem'
    },
    trusteeInstitution: {
      fontSize: '0.9rem',
      color: '#888888',
      fontStyle: 'italic',
      marginBottom: '1rem'
    },
    socialLinks: {
      display: 'flex',
      gap: '1rem',
      marginTop: '1rem'
    },
    socialIcon: {
      width: '24px',
      height: '24px',
      opacity: '0.7',
      transition: 'opacity 0.2s ease',
      cursor: 'pointer'
    },
    description: {
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#555555'
    },
    // Hover states
    cardHover: {
      transform: 'translateY(-5px)'
    },
    iconHover: {
      opacity: '1'
    }
  };

  const handleTwitter = () => {
    const twitter = 'https://twitter.com/otc_london';
    window.open(twitter, '_blank', 'noopener,noreferrer');
  };

  const handleLinkedIn = () => {
    const linkedIn = 'https://www.linkedin.com/company/offthecurriculum-otc/';
    window.open(linkedIn, '_blank', 'noopener,noreferrer');
  };

  const ourteam = [
    {
      name: 'Dr. Joseph Michael Levermore',
      role: 'Chief Executive and Founder',
      quote: '"The unexamined life is not worth living." ― Socrates',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Fplaceholder-officers%2FjosephLevermore.webp?alt=media&token=53a6caf9-8580-47f7-80ed-c2c32bbe535a',
      description: `Researcher at Imperial College London and founder of Off the Curriculum, driven by a vision to enhance educational opportunities in Lambeth and Southwark. Combines expertise in science with a passion for educational empowerment and social mobility.`,
      emailId: 'josephEmail'
    },
    {
      name: 'Jawaria Suleman',
      role: 'Education Officer',
      quote: '"The more that you learn, the more you realize how little you know." ― Voltaire',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Fplaceholder-officers%2FjawarieSuleman.webp?alt=media&token=b73b4e28-f229-461e-b5da-423ec975516a',
      description: `Distinguished chemistry educator with international teaching experience. Holds an M.Sc. in Educational Studies with distinction from the University of Strathclyde. Dedicated to addressing educational disparities and creating advancement opportunities.`,
      emailId: 'jawariaEmail'
    },
    {
      name: 'Nicole T Levermore, MSc',
      role: 'Education Officer',
      quote: `"You don't have to be the person you were fifteen minutes ago." ― N. Levermore`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Fplaceholder-officers%2FnicoleLevermore.webp?alt=media&token=06a4723c-ffe8-44d2-9cf5-1648df84ede8',
      description: `Ph.D. candidate in Auditory Neuroscience at the University of Brighton, with an MSc in Translational Neuroscience from Sheffield. Combines academic expertise with creative pursuits, including classical music and cello performance.`,
      emailId: 'nicoleLEmail'
    },
    {
      name: 'Dhaval Shah, MSc',
      role: 'Astrophysics Expert Panelist',
      quote: `"Everything seems impossible until it has been achieved." ― Nelson Mandela`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Fplaceholder-officers%2FdhavalShah.webp?alt=media&token=e5809726-f8a5-4c7c-832c-e0338be64805',
      description: `MSc graduate in Data Intensive Astrophysics from Cardiff University, specializing in multi-messenger gravitational waves research. Combines technical expertise with a passionate dedication to exploring and understanding the cosmos.`,
      emailId: 'dhavalEmail'
    },
    {
      name: 'Tim Siu',
      role: 'Recruitment Team Lead',
      quote: '"To thrive in life requires passion, purpose, and perseverance." ― Maya Angelou',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Fplaceholder-officers%2FtimSiu.webp?alt=media&token=4669e90a-5bba-4957-9b97-be8aa4d08b2e',
      description: `Executive Talent Acquisition Lead for Tax across EMEIA at EY, with extensive experience in senior-level recruitment. Former journalist with international experience, including two years in Japan. Specializes in connecting talent with transformative opportunities.`, 
      emailId: 'timEmail'
    },
    {
      name: 'Grace Phang Yen Peng',
      role: 'Recruitment Officer',
      quote: `"Within the impossible lies the possible waiting to emerge." ― Anonymous`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Fplaceholder-officers%2FgracePhang.webp?alt=media&token=a83c8a61-dd83-4fc6-b885-b06b45170097',
      description: `Strategic Talent Acquisition Manager with over 10 years of specialized IT recruitment experience. Expert in end-to-end recruitment processes, focusing on connecting professionals with career-defining opportunities and inspiring future career paths.`, 
      emailId: 'graceEmail'
    },
    {
      name: 'Samuel Animashaun',
      role: 'Finance Officer',
      quote: `"A passion for learning is the path to endless growth." ― A.J. D'Angelo`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Fplaceholder-officers%2FsamuelAnimashaun.webp?alt=media&token=03ccdf8e-5332-4678-9d3c-8f458266c9b4',
      description: `MSc candidate in Financial Technology and upcoming ACCA Chartered Accountant. Combines financial expertise with a commitment to continuous learning. Driven by the mission to create positive impact through educational empowerment.`, 
      emailId: 'samuelEmail'
    }
  ];

  const mailProtector = () => {
    const p = "mailto:josxexphxlxxevxxermore@offthecurrxiculumx.cox.uxk";
    const emailMappings = [
      {
        email: "mailto:josephlevermore@offthecurriculum.co.uk",
        elementId: "josephEmail"
      },
      {
        email: "mailto:jawariasuleman@offthecurriculum.co.uk",
        elementId: "jawariaEmail"
      },
      {
        email: "mailto:nicolelevermore@offthecurriculum.co.uk",
        elementId: "nicoleLevermoreEmail"
      },
      {
        email: "mailto:dhavalshah@offthecurriculum.co.uk",
        elementId: "dhavalShahEmail"
      },
      {
        email: "mailto:samuelanimashaun@offthecurriculum.co.uk",
        elementId: "samuelEmail"
      },
      {
        email: "mailto:timsiu@offthecurriculum.co.uk",
        elementId: "timsiuEmail"
      },
      {
        email: "mailto:gracephang@offthecurriculum.co.uk",
        elementId: "gracephangEmail"
      }
    ];

    emailMappings.forEach(({ email, elementId }) => {
      const protectedEmail = p.replace(
        "mailto:josxexphxlxxevxxermore@offthecurrxiculumx.cox.uxk",
        email
      );
      const element = document.getElementById(elementId);
      if (element) {
        element.href = protectedEmail;
      }
    });
  };

  return (
    <div style={styles.mainContainer}>
      <h1 style={styles.header}>Our Team</h1>
      <p style={styles.introduction}>
        Behind Off the Curriculum stands a remarkable team of changemakers. Our officers come from diverse professional backgrounds but share an unwavering commitment to educational empowerment. From groundbreaking researchers to industry leaders, each member brings unique insights and expertise to our mission. Meet the passionate individuals driving our vision forward:
      </p>

      <div style={styles.trusteesGrid}>
        {ourteam.map((member, index) => (
          <div key={index} style={styles.trusteeCard}>
            <div style={styles.imageContainer}>
              <img
                style={styles.image}
                src={member.image}
                alt={`${member.name} - Team member at Off the Curriculum`}
              />
            </div>
            <div style={styles.cardContent}>
              <h3 style={styles.trusteeTitle}>{member.name}</h3>
              <h4 style={styles.trusteePosition}>{member.role}</h4>
              <h4 style={styles.trusteeInstitution}>{member.quote}</h4>
              <p style={styles.description}>{member.description}</p>
              <div style={styles.socialLinks}>
                <a
                  id={member.emailId}
                  href="mailProtector:;"
                  onClick={mailProtector}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <CiMail style={styles.socialIcon} />
                </a>
                <RiTwitterXLine 
                  onClick={handleTwitter}
                  style={styles.socialIcon}
                />
                <CiLinkedin 
                  style={styles.socialIcon}
                  onClick={handleLinkedIn}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurTeam;