import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CiClock1 } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { IoTicketOutline } from "react-icons/io5";
import { motion } from 'framer-motion';
import ThingsYouNeedComponent from '../../EDUCO/Profile/RegisteredEvents/Components/ThingsYouWillNeed/ThingsYouNeed';
import UponArrivalComponent from '../../EDUCO/Profile/RegisteredEvents/Components/UponArrival/UponArrival';
import MapComponent from '../../EDUCO/Profile/RegisteredEvents/Components/Map/Map';
import GuestSpeaker from './Components/GuestSpeaker';
import LearningObjectives from './Components/LearningObjectives';
import * as EventsData from './data/index';
import AttendingOfficersComponent from './Components/AttendingOfficers';
import { Link } from 'react-router-dom';
import StandardButtonContainer from '../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import SideTabRegister from './Components/SideTabRegistration';


const EventsTemplatePage = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentEvent, setCurrentEvent] = useState(null);

  useEffect(() => {
    if (eventId) {
      // Log available events for debugging
      console.log('Available events:', EventsData);
      
      const eventData = EventsData[`${eventId}Event`];
      console.log('Looking for event:', `${eventId}Event`);
      console.log('Found event data:', eventData);
      
      if (eventData) {
        setCurrentEvent(eventData);
      } else {
        console.error('Event not found:', eventId);
        navigate('/events');
      }
    }
  }, [eventId, navigate]);
  

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!currentEvent) {
    return <div>Loading event details...</div>;
  }
  

  const handleBackToEvents = () => {
    navigate('/events');
  };


  const styles = {
    container: {
      display: 'flex',
      maxWidth: '1400px',
      margin: '0 auto',
      padding: '2rem',
      gap: '2rem',
      minHeight: '140vh', // Add this to ensure proper height context
    },
    mainContent: {
      flex: 1,
      minWidth: 0,
      position: 'relative', // Add this
    },
    contentContainer: {
      display: 'flex',
      gap: '2rem',
      position: 'relative',
      marginTop: '2rem',
      flexDirection: windowWidth <= 768 ? 'column' : 'row',
      minHeight: '100%', // Add this
    },
    eventColumnTwo: {
      flex: windowWidth <= 768 ? '1' : '0 0 75%',
      maxWidth: windowWidth <= 768 ? '100%' : '75%',
    },
  
  
    mobileRegisterContent: {
      display: windowWidth <= 768 ? 'block' : 'none', // Only show on mobile
      marginTop: '2rem',
      padding: '1.5rem',
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
    },
  
    registerBox: {
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      padding: '1.5rem',
      transition: 'transform 250ms',
      maxWidth: windowWidth <= 768 ? '100%' : 'auto',
      margin: windowWidth <= 768 ? '0 auto' : '0',
    },
    eventColumnOne: {
      display: windowWidth <= 768 ? 'none' : 'block', // Hide on mobile
      flex: windowWidth <= 768 ? '1' : '0 0 25%',
      position: windowWidth <= 768 ? 'relative' : 'sticky',
      bottom: windowWidth <= 768 ? '130px' : 'auto',
      top: windowWidth <= 768 ? 'auto' : '10rem',
      left: windowWidth <= 768 ? '0' : 'auto',
      width: windowWidth <= 768 ? '100%' : 'auto',
      zIndex: windowWidth <= 768 ? '1000' : '1',
      padding: windowWidth <= 768 ? '1rem' : '20px',
      backgroundColor: windowWidth <= 768 ? '#ffffff' : '#ffffff',
      height: 'fit-content', // Add this
      alignSelf: 'flex-start', // Add this
      borderRadius: '25px', 
      boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
    },
    metaInfoCard: {
      display: windowWidth <= 768 ? 'none' : 'block', // Hide meta info on mobile
      backgroundColor: '#ffffff',
      padding: '1rem',
      borderRadius: '8px',
      marginBottom: '1rem',
      border: '1px solid #e2e8f0',
    },

    registerButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    registerButton: {
      width: windowWidth <= 768 ? '90%' : 'auto',
    },
    sidebar: {
      width: '300px',
      position: 'sticky',
      top: '2rem',
      height: 'fit-content',
      padding: '1.5rem',
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
    },
    mainContent: {
      flex: 1,
      minWidth: 0
    },
    header: {
      fontWeight: 'bold',
      marginBottom: '1.5rem',
    },
    filterButton: (isActive) => ({
      width: '100%',
      padding: '0.75rem',
      marginBottom: '0.5rem',
      borderRadius: '8px',
      border: 'none',
      cursor: 'pointer',
      backgroundColor: isActive ? '#2C5282' : '#f3f4f6',
      color: isActive ? '#ffffff' : '#4b5563',
      fontWeight: '500',
      textAlign: 'left',
      transition: 'all 0.3s ease'
    }),
    eventsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gap: '1.5rem',
      marginBottom: '2rem'
    },
    eventCard: {
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      cursor: 'pointer'
    },
    eventImage: {
      width: '100%',
      height: '200px',
      objectFit: 'cover',        
      objectPosition: 'center top', // This ensures the top of the image is always visible
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    },
    eventContent: {
      padding: '1.5rem'
    },
    eventTitle: {
      fontWeight: '600',
      marginBottom: '1rem',
    },
    metaInfo: {
      display: windowWidth <= 768 ? '' : 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      color: '#666666',
      fontSize: '0.9rem',
      marginBottom: '0.5rem'
    },
    tag: (category) => ({
      display: 'inline-block',
      padding: '0.25rem 0.75rem',
      borderRadius: '999px',
      fontSize: '0.875rem',
      fontWeight: '500',
      ...getTagColor(category)
    }),
    selectedEventView: {
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      padding: '2rem',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
    },
    eventDetailImage: {
      width: '100%',
      height: '400px',
      objectFit: 'cover',
      borderRadius: '8px',
      marginBottom: '2rem',
      objectPosition: 'center top', // This ensures the top of the image is always visible
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    },
    quickLinks: {
      display: 'flex',
      gap: '1rem',
      marginBottom: '2rem'
    },
    quickLinkButton: {
      padding: '0.75rem 1.5rem',
      backgroundColor: '#2C5282',
      color: '#ffffff',
      borderRadius: '8px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease'
    },
    registrationForm: {
      width: '100%',
      minHeight: '500px',
      border: 'none',
      borderRadius: '8px',
      marginTop: '2rem'
    },
    contentContainer: {
      display: 'flex',
      gap: '2rem',
      position: 'relative',
      marginTop: '2rem',
    },
    registerTitle: {
      marginBottom: '1rem',
      textAlign: 'center',
      fontWeight: '500',
    },
    metaInfoItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      marginBottom: '0.75rem',
      color: '#4a5568',
      fontSize: '0.9rem',
    },
    divider: {
      height: '1px',
      backgroundColor: '#e2e8f0',
      margin: '0.75rem 0',
    },
    hiddenOnMobile: {
      display: windowWidth <= 768 ? 'block' : 'none',
    }
  };

  const getTagColor = (category) => {
    switch (category) {
      case 'mighty-minds':
        return { backgroundColor: '#EEF7F8', color: '#4dabb5' };
      case 'seminar-series':
        return { backgroundColor: '#FCEFEE', color: '#e56251' };
      case 'workshops':
        return { backgroundColor: '#FDF5E8', color: '#f3ad1b' };
      default:
        return { backgroundColor: '#F5F5F5', color: '#666666' };
    }
  };

  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };
  

  return (
    <div style={styles.container}>
    <div style={styles.mainContent}>
      <button 
        onClick={() => navigate('/events')}
        style={{ marginBottom: '1rem', color: '#1F484C', cursor: 'pointer', background: 'none', border: 'none' }}
      >
        ← Back to all events
      </button>
      
      <img src={currentEvent.imageSrc} alt={currentEvent.title} style={styles.eventDetailImage} />
      <div style={styles.hiddenOnMobile}>
          <SideTabRegister eventId={currentEvent.id} location={`/events/register/${currentEvent.id}`} />
        </div>
      <div style={styles.tag(currentEvent.category)}>{currentEvent.category}</div>
      <h1 style={styles.header}>{currentEvent.title}</h1>

      <div style={styles.contentContainer}>
        {/* Main Content Column */}
        <div style={styles.eventColumnTwo}>
          <div style={styles.metaInfo}>
            <CiClock1 />
            <span>{currentEvent.time}</span>
            <span style={{ margin: '0 0.5rem' }}>•</span>
            <IoLocationOutline />
            <span>{currentEvent.location}</span>
            <span style={{ margin: '0 0.5rem' }}>•</span>
            <IoTicketOutline />
            <span>{currentEvent.ticketNumber}</span>
          </div>

          <p style={{ marginTop: '1.5rem', lineHeight: '1.6', color: '#000' }}>
            {currentEvent.description}
          </p>

          <MapComponent mapLocation={currentEvent.mapLocation} />
          
          {currentEvent.speaker && (
            <div id="speaker" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
              <GuestSpeaker speaker={currentEvent.speaker} />
            </div>
          )}

          {currentEvent.learningObjectives && (
            <div id="objectives" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
              <LearningObjectives objectives={currentEvent.learningObjectives} />
            </div>
          )}

          <div id="thingsYouNeed">
            <ThingsYouNeedComponent requirements={currentEvent.requirements} />
          </div>

          <div id="attendingOfficers">
            <AttendingOfficersComponent officers={currentEvent.officers} />
          </div>
        </div>

        {/* Sticky Registration Column */}
        <motion.div 
          style={styles.eventColumnOne}
          whileHover={{ 
            scale: 1.02,
            boxShadow: 'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.05) 0px 16px 48px',
            transition: { type: "spring", stiffness: 300 }
          }}
          initial={{ scale: 1 }}
        >
          <h3 style={styles.registerTitle}>
            {currentEvent.registerHere}
          </h3>
          <div style={styles.metaInfoCard}>
            <div style={styles.metaInfoItem}>
              <CiClock1 />
              <span>{currentEvent.time}</span>
            </div>
            <div style={styles.divider} />
            <div style={styles.metaInfoItem}>
              <IoLocationOutline />
              <span>{currentEvent.location}</span>
            </div>
            <div style={styles.divider} />
            <div style={styles.metaInfoItem}>
              <IoTicketOutline />
              <span>{currentEvent.ticketNumber} spots remaining</span>
            </div>
          </div>
          <div style={styles.registerButtonContainer}>
            <Link to={`/events/register/${currentEvent.id}`}>
              <StandardButtonContainer.ButtonTwo>
                Register here!
              </StandardButtonContainer.ButtonTwo>
            </Link>
          </div>
        </motion.div>
        </div>
      </div>
    </div>
  );
};

export default EventsTemplatePage;