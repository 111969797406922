import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import ShortsVideoComponent from './Components/Shorts/videoComponent';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import VideoCard from '../../../Components/EDUCO/VideoCard/VideoCard';
// components
import LoadingComponent from '../../../Components/Common/Loading/LoadingComponent';
import BottomLoader from '../../../Components/Common/Loading/BottomLoaderComponent';
import useAuth from '../../../Firebase/Auth';
import MetaTags from '../../../Components/Common/MetaTags/metaTagsComponent';
import { pageMetaTags } from '../../../Components/Common/MetaTags/metaData';

// loading circles
import useSetIsContentLoaded from '../../../Components/Common/LoadingCircles/LoadingCircles';
// onHover Image to video short player
import { ImageComponent } from './Components/VideoImageComponent';

//firebase functions
import { fetchUserRecommendedVideoData, fetchMoreVideoData, fetchUserRecommendedShortsVideoData, emailsHasher } from '../../../Firebase/educoFunctions';

//verification 
import VerificationBanner from './Components/VerificationComponent/VerificationComponent';
import { functions } from '../../../Firebase/firebaseConfig';
import { httpsCallable } from 'firebase/functions';


const ExploreComponent = () => {
  const meta = pageMetaTags.ExploreEduco;
  const currentUser = useAuth();
  const mediaQuery = window.matchMedia('(min-width: 768px)');
  const [videos, setVideos] = useState([]);
  const [shortsVideos, setShortsVideos] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loadMoreVideosRef = useRef(null);
  const [theme, setTheme] = useState('light');
  const [shortsVideoCount, setShortsVideoCount] = useState(mediaQuery.matches ? 4 : 2);
  const [lastViewedVideoTitles, setLastViewedVideoTitles] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationChecked, setVerificationChecked] = useState(false);
  const verificationCheckMade = useRef(false);
  const initialFetchMade = useRef(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useSetIsContentLoaded(1000);

  // Memoized layout calculations
  const responsiveLayout = useMemo(() => {
    if (windowWidth >= 1200) {
      return { width: 'calc(33.333% - 70px)' };
    } else if (windowWidth >= 768) {
      return { width: 'calc(50% - 70px)' };
    }
    return { width: 'calc(100% - 40px)' };
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      
      // Update shorts video count based on width
      if (window.matchMedia('(min-width: 1610px)').matches) {
        setShortsVideoCount(6);
      } else if (window.matchMedia('(min-width: 1400px)').matches) {
        setShortsVideoCount(5);
      } else if (window.matchMedia('(min-width: 1088px)').matches) {
        setShortsVideoCount(4);
      } else if (window.matchMedia('(min-width: 815px)').matches) {
        setShortsVideoCount(3);
      } else {
        setShortsVideoCount(2);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
    //console.log('responsiveLayout', responsiveLayout)
  // Inline styles
  const containerStyle = {
    //padding: '20px',
    color: '#fff',
    margin: 'auto',
  };
  const videoListStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center',
    marginTop: '20px',
    //padding: window.innerWidth < 768 ? '10px' : '20px'
  };

  const videoInfoStyle = {
    flex: '1',
  };
  // light and dark mode
  
  const styles = useMemo(() => {
    const baseStyles = theme === 'light' ? lightTheme : darkTheme;
    const responsiveStyles = { ...baseStyles };

    if (mediaQuery.matches) {
      responsiveStyles.videoItemStyle = {
        ...responsiveStyles.videoItemStyle,
        height: '250px',
      };
      responsiveStyles.thumbnailStyle = {
        ...responsiveStyles.thumbnailStyle,
        height: '165px',
      };
    }

    return responsiveStyles;
  }, [theme, mediaQuery.matches]);

  // Memoized link styling
  const linkStyling = useMemo(() => ({
    ...responsiveLayout,
    textDecoration: 'none',
    margin: '10px',
    minWidth: windowWidth < 768 ? '280px' : 'auto',
    maxWidth: windowWidth < 768 ? '100%' : 'auto'
  }), [responsiveLayout, windowWidth]);

  // Fetch initial videos - Modified to prevent double loading
  useEffect(() => {
    const fetchInitialVideos = async () => {
      if (!currentUser?.email || initialFetchMade.current) return;
      
      initialFetchMade.current = true;
      
      try {
        setIsInitialLoading(true);
        const [initialVideosResponse, shortsVideosResponse] = await Promise.all([
          fetchUserRecommendedVideoData(currentUser.email),
          fetchUserRecommendedShortsVideoData(currentUser.email, shortsVideoCount)
        ]);
        
        setShortsVideos(shortsVideosResponse.videos);
        setVideos(initialVideosResponse.videos);
        setLastViewedVideoTitles(initialVideosResponse.videos.map(video => video.key));
      } catch (error) {
        console.error('Error fetching initial content:', error);
      } finally {
        setIsInitialLoading(false);
      }
    };

    fetchInitialVideos();
  }, [currentUser?.email, shortsVideoCount]);

  // Load more videos function
  const loadMoreVideos = useCallback(async () => {
    if (isLoadingMore || !currentUser?.email) return;

    setIsLoadingMore(true);
    try {
      const moreVideos = await fetchMoreVideoData(6, lastViewedVideoTitles);
      const formattedNewVideos = moreVideos.map(videoData => ({
        key: videoData.key,
        videohref: videoData.videohref,
        videoImage: videoData.videoImage,
        author: videoData.author,
        count: videoData.count,
        likes: videoData.likes,
      }));
      
      setLastViewedVideoTitles(prev => [...prev, ...moreVideos.map(video => video.key)]);
      setVideos(prev => [...prev, ...formattedNewVideos]);
    } catch (error) {
      console.error('Error loading more videos:', error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [isLoadingMore, currentUser?.email, lastViewedVideoTitles]);


  // Function to load more shorts videos
  const loadRecomendedShortsVideos = async () => {
    try {
      const newShortsVideos = await fetchUserRecommendedShortsVideoData(currentUser.email, shortsVideoCount);
      //console.log('hello', newShortsVideos);
      setShortsVideos(prevShorts => [...prevShorts, ...newShortsVideos.videos]);
    } catch (error) {
      console.error("Error fetching shorts videos: ", error);
    }
  };

  // Fetch initial videos
  useEffect(() => {
    const fetchInitialVideos = async () => {
      if (!currentUser?.email) return; // Add this line to check for null
    
      try {
        setIsInitialLoading(true);
        const initialVideos = await fetchUserRecommendedVideoData(currentUser.email);
        const newShortsVideos = await fetchUserRecommendedShortsVideoData(currentUser.email, shortsVideoCount);
        setShortsVideos(newShortsVideos.videos);
        setVideos(initialVideos.videos);
      } catch (error) {
        console.error('Error fetching recommended videos:', error);
      } finally {
        setIsInitialLoading(false);
      }
    };
    fetchInitialVideos();
  }, [currentUser]);


  // Detect scroll to the bottom of the page
  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 100 && !isLoadingMore && !isInitialLoading) {
        loadMoreVideos();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoadingMore, isInitialLoading, loadMoreVideos]);

  useEffect(() => {
    const checkVerificationStatus = async () => {
      if (!currentUser?.email || verificationCheckMade.current) return;
      
      verificationCheckMade.current = true;
      
      try {
        const checkStatus = httpsCallable(functions, 'check_verification_status');
        const hashedEmail = emailsHasher(currentUser.email);
        
        const result = await checkStatus({
          userId: hashedEmail
        });
        
        //console.log('Verification status:', result);

        // Only set isVerified to true if status is 'verified'
        setIsVerified(result.data.verification_status === 'verified');
        setVerificationChecked(true);
      } catch (error) {
        console.error('Verification check error:', error);
        setVerificationChecked(true);
      }
    };
  
    checkVerificationStatus();
  }, [currentUser?.email]);

  // handle verification of the user is true
  const handleVerification = async (code) => {
    if (!currentUser?.email) return;
    
    setIsVerifying(true);
    try {
      const verifyAndWelcome = httpsCallable(functions, 'verify_and_send_welcome');
      const hashedEmail = emailsHasher(currentUser.email);
      const result = await verifyAndWelcome({
        userId: hashedEmail,
        verification_code: code
      });
  
      if (result.data.success) {
        setIsVerified(true);
        // Show welcome message
        alert('Welcome to Off The Curriculum! Your account has been verified.');
      } else {
        throw new Error(result.data.message || 'Verification failed');
      }
    } catch (error) {
      alert('Failed to verify code. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  if (mediaQuery.matches) {
    Object.assign(styles.videoItemStyle, {
      height: '250px',
    });

    Object.assign(styles.thumbnailStyle, {
      height: '165px',
    });
  }

  const shuffledShortsVideos = useMemo(() => {
    const shuffleAndSelectVideos = (videos, num) => {
      return [...videos].sort(() => 0.5 - Math.random()).slice(0, num);
    };
    return shuffleAndSelectVideos(shortsVideos, shortsVideoCount);
  }, [shortsVideos, shortsVideoCount]);
  
  return ( 
    <div style={containerStyle}>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
    
    {verificationChecked && !isVerified && (
        <VerificationBanner 
          onVerify={handleVerification}
          isVerifying={isVerifying}
          windowWidth={windowWidth}
          currentUser={currentUser}
        />
      )}
      
      {isInitialLoading ? (
        <LoadingComponent 
          isLoading={true}
          message="Loading your recommended content..."
          backgroundColor={theme === 'dark' ? "rgba(32, 32, 32, 0.95)" : "rgba(255, 255, 255, 0.95)"}
          textColor={theme === 'dark' ? "#FFFFFF" : "#333333"}
        />
      ) : (
        <>
          {/*<button onClick={toggleTheme}>Toggle Theme</button>*/}
          <div style={videoListStyle}>
            {videos.map((video, index) => {
              // Create a unique compound key using multiple properties
              const uniqueVideoKey = `${video.key}-${video.videohref}-${index}`;
              
              return (
                <React.Fragment key={uniqueVideoKey}>
                  <VideoCard
                    key={`card-${uniqueVideoKey}`}
                    video={video}
                    theme={theme}
                    linkStyling={linkStyling}
                    windowWid={windowWidth}
                  />
                  {index % 6 === 5 && index !== videos.length - 2 && (
                    <div key={`shorts-section-${uniqueVideoKey}`} style={{ width: '100%' }}>
                      <h2 style={styles.shortsHeader}>Shorts</h2>
                      <ShortsVideoComponent 
                        key={`shorts-${uniqueVideoKey}`}
                        videos={shuffledShortsVideos.map((shortVideo, shortIndex) => ({
                          ...shortVideo,
                          // Add a unique identifier to each short video
                          uniqueKey: `${shortVideo.key}-${shortIndex}`
                        }))} 
                        theme={theme} 
                      />
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          
          <BottomLoader 
            isLoading={isLoadingMore}
            message="Loading more videos..."
            spinnerColor={theme === 'dark' ? "#54AFB8" : "#54AFB8"}
            textColor={theme === 'dark' ? "#FFFFFF" : "#333333"}
          />
        </>
      )}
    </div>
  );
};

export default ExploreComponent;

const lightTheme = {
  thumbnailStyle: {
    width: '100%',
    height: '200px',
    backgroundColor: '#fff',
    marginBottom: '10px',
    borderRadius: '10px',
  }, 
  containerStyle: {
    padding: '20px',
    color: '#000',
    backgroundColor: '#fff',
    margin: 'auto',
  },
  videoItemStyle: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f0f0f0',
    marginBottom: '15px',
    borderRadius: '15px',
    boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)',
    padding: '10px',
    cursor: 'pointer'
  },
  videoDetailsStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'darkgrey',
    fontSize: '0.9em',
  },
  videoTitle:{
    marginBottom: '15px', 
    color: '#000',
  },
  shortsHeader: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: 'rgb(255, 111, 97)',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    textAlign: 'center',
    border: '2px solid rgb(255, 111, 97)',
    borderRadius: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    width: '125px',
    marginLeft: '60px',
    padding: '10px',
    backgroundColor: '#fff'
  }
};

const darkTheme = {
  thumbnailStyle: {
    width: '100%',
    height: '200px',
    backgroundColor: '#303030',
    marginBottom: '10px',
    borderRadius: '10px',
  }, 
  containerStyle: {
    padding: '20px',
    color: '#fff',
    backgroundColor: '#202020',
    margin: 'auto',
  },
  videoItemStyle: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#303030',
    marginBottom: '15px',
    borderRadius: '15px',
    boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)',
    padding: '10px',
  },
  videoDetailsStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'lightgrey',
    fontSize: '0.9em',
  },
  videoTitle:{ 
    color: '#fff',
    marginBottom: '15px', 
  },
  shortsHeader: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: 'rgb(255, 111, 97)',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    textAlign: 'center',
    border: '2px solid rgb(255, 111, 97)',
    borderRadius: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    width: '125px',
    marginLeft: '60px',
    padding: '10px',
    backgroundColor: '#303030'
  }
};

