import React, { useState, createContext, useEffect, useLayoutEffect, useContext} from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { auth } from './Firebase/firebaseConfig';
import { onAuthStateChanged, getIdTokenResult } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import RoutePages from './Roots/RootPages';
import NavBar from './Components/Common/NavBar/NavBar';
import Footer from './Components/Common/Footer/Footer';
import PageWrapper from './Components/Common/StyledPageWrapper/StyledPapperWrapper';
import EDUCONavBarComponent from './Components/Common/NavBar/EDUCONavBar/educoNavBar';
import SignedInRoutePages from './Roots/SignedInRootsPages';
import { LoadingContext, LoadingProvider } from './Roots/LoadingContent';
import { ThemeContext, ThemeProvider } from './Components/Common/ThemeContext/ThemeContext';
import GA4React from "ga-4-react";
import { useNavigate } from 'react-router-dom';
import { emailsHasher } from './Firebase/educoFunctions';
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from "firebase/auth";
import UnLoadedCoursePageContent from './Pages/EDUCO/Courses/Components/UnloadedCourseVideoPage';
export const UserEmail = createContext();
const ga4react = new GA4React("G-18FPSPY08Q", {debug_mode: true});

const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isTeacher, setIsTeacher] = useState(false);
  const { isContentLoaded } = React.useContext(LoadingContext);
  const [isUserSignedIn, setIsUserSignedIn] = useState(true);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [mentorStatus, setMentorStatus] = useState(null);
  
  function ScrollToTop({ children }) {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  }
  //console.log(auth)


  // Function to check if profile exists
  const checkUserProfile = async (email) => {
    const db = getDatabase();
    const hashedEmail = emailsHasher(email);
    const profileRef = ref(db, `person/${hashedEmail}`);
    
    try {
      const snapshot = await get(profileRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const epqData = userData.EPQ || {};
        
        // Check mentoring status
        const hasMentor = epqData.mentorRequestSent && epqData.mentorStatus === 'accepted';
        const hasPendingRequest = epqData.mentorRequestSent && epqData.mentorStatus === 'pending';
        
        return {
          exists: true,
          mentorStatus: {
            hasMentor,
            hasPendingRequest,
            currentRequestId: epqData.currentRequestId || null,
            requestedMentorId: epqData.requestedMentorId || null
          }
        };
      }
      return { exists: false, mentorStatus: null };
    } catch (error) {
      console.error("Error checking user profile:", error);
      return { exists: false, mentorStatus: null };
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setEmail(user.email);
        UserEmail.Provider = user.email;
        setIsUserSignedIn(true);
  
        try {
          const profileData = await checkUserProfile(user.email);
          
          if (!profileData.exists) {
            navigate('/createauserprofile');
          } else {
            setMentorStatus(profileData.mentorStatus);
            const idTokenResult = await getIdTokenResult(user);
            if (!!idTokenResult.claims.admin) {
              // Handle admin UI
            }
          }
        } catch (error) {
          console.error("Error during profile check:", error);
        }
      } else {
        setIsUserSignedIn(false);
        setMentorStatus(null);
      }
    });
  
    return () => unsubscribe();
  }, [navigate]);


  useEffect(() => {
    ga4react.initialize().then(
      (ga4) => {
        ga4.pageview("path");
        ga4.gtag("pageview", "path"); // or your custom gtag event
      },
      (err) => {
      }
    );
  });


  const ThemeToggle = () => {
    const { theme, toggleTheme } = useContext(ThemeContext);
    return (
      <button onClick={toggleTheme}>
        Switch to {theme === 'light' ? 'Dark' : 'Light'} Mode
      </button>
    );
  };

  const renderContent = () => {
    const auth = getAuth();
    {/*setPersistence(auth, browserSessionPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        localStorage.clear();
        sessionStorage.clear();
    
        // New sign-in will be persisted with session persistence.
        return signInWithEmailAndPassword(auth);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
      });*/}
    return (
      <ScrollToTop>
        <ThemeProvider>
          {isUserSignedIn ? <EDUCONavBarComponent mentorStatus={mentorStatus} /> : <NavBar />}
          <PageWrapper>
            {isUserSignedIn ? <SignedInRoutePages /> : <RoutePages />}
          </PageWrapper>
          <Footer />
        </ThemeProvider>
      </ScrollToTop>
    );
  };

  return renderContent();
};

const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </HelmetProvider>
  );
};

const AppWithProvider = () => (
  <LoadingProvider>
    <App />
  </LoadingProvider>
);

export { AppWithProvider };
export default AppWithProvider;