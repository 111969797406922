import React, {useState, useEffect} from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

// Non-logged in content
import Home from '../Pages/General/Home';
import About from '../Pages/General/About';
import NotFound from '../Components/Common/NotFound/NotFound';
import ResourceLibrary from '../Pages/General/ResourceLibrary/ResourceLibrary';
import Education from '../Pages/General/Education/Education';
import Volunteer from '../Pages/General/Volunteering/Volunteer';
import BecomeAnOfficer from '../Pages/General/Volunteering/becomeAVolunteer';
import Outreach from '../Pages/General/Outreach/Outreach';
import Donate from '../Pages/General/Donate/Donate';
import Comingsoon from '../Components/Common/ComingSoon/ComingSoon';
// SignInComponent and Registration
import SignInComponent from '../Pages/General/Sign-In-Registration/SignIn';
import ForgotPasswordComponent from '../Pages/General/Sign-In-Registration/ForgotPassword';
import CreateAccountComponent from '../Pages/General/Sign-In-Registration/CreateAccount';
import CreateAUserProfileComponent from '../Pages/EDUCO/CreateAUserProfile/createAUserProfile';
import UploadingProfile from '../Pages/EDUCO/CreateAUserProfile/uploadingProfile';

// Statements
import Statements from '../Pages/General/ResourceLibrary/Statements';
import DiversityStatement from '../Pages/General/About/Statements/diversityInclusionStatement';
import EDICommitmentComponent from '../Pages/General/About/Statements/EDICommitmentComponent';

//firebase fetch request
import { fetchPageRoutes, fetchCommunitiesRoutes, fetchCourseRoutes, fetchMentorsRoutes } from '../Firebase/routeUtils';

// signedIn routes
import ExploreComponent from '../Pages/EDUCO/Explore/Explore';
import CourseExplorePage from '../Pages/EDUCO/Courses/CourseExplorePage';
import CourseVideoPage from '../Pages/EDUCO/Courses/Components/CourseVideoPage';
import CommunitiesExplorePage from '../Pages/EDUCO/Communities/CommunitiesExplorePage';

import MentorsExplorePage from '../Pages/EDUCO/Mentors/MentorsExplorePage';
import MentorProgrammePage from '../Pages/EDUCO/Mentors/MentorProgrammePage';


import StudioDashboard from '../Pages/EDUCO/Studio/StudioDashboard';


// educo routes
import VideoPage from '../Pages/EDUCO/VideoPages/VideoTemplatePage';
import EnrolledCoursePage from '../Pages/EDUCO/Courses/EnrolledCoursePage';
import QuizResult from '../Pages/EDUCO/Courses/Components/QuizComponent/ResultPage/QuizResult';

import { LoadingContext, LoadingProvider } from './LoadingContent';
import ProfilePage from '../Pages/EDUCO/Profile/ProfileTemplatePage';

//admin 
import AdminHomepage from '../Pages/Admin/PortalHomepage/AdminHomePage';
import EmailFooterGenerator from '../Pages/Admin/OfficerComponents/CreateFooter/CreateFooter';

import NewsArticle from '../Pages/General/News/newsArticle';

// community routes
import CreateCommunity from '../Pages/EDUCO/Communities/CreateCommunities';
import CommunityPage from '../Pages/EDUCO/Communities/Components/CommunityPage';
import PostPageComponent from '../Pages/EDUCO/Communities/Components/PostPageComponent';
import GrievanceProcedure from '../Pages/General/ResourceLibrary/GrievanceProcess';
import GrievanceForm from '../Pages/General/ResourceLibrary/GrievanceForm';
import MentalHealthCharitiesGuide from '../Pages/General/ResourceLibrary/CharityHelplines';
import WhistleblowingPolicyGuide from '../Pages/General/ResourceLibrary/WhistleblowingPolicyGuide';
import MentoringFeedbackForm from '../Pages/EDUCO/Mentors/MentoringFeedbackForm/MentoringFeedbackForm';
import Enrol from '../Pages/General/Education/Enrol';
import OurTrustees from '../Pages/General/About/MeetTheTrustees/meetTheTrustees';
import OurTeam from '../Pages/General/About/MeetTheTeam/meetTheTeam';
import News from '../Pages/General/News/newspage';
import Policies from '../Pages/General/Policies/PolicyListPage';
import ContactForm from '../Pages/General/ContactUsForm/ContactUsForm';

import LoadingComponent from '../Components/Common/Loading/LoadingComponent';

// events 
import EventsPortal from '../Pages/General/Events/eventsMainPage';
import EventsTemplatePage from '../Pages/General/Events/eventsTemplatePage';
import EventRegistrationPage from '../Pages/General/Events/EventRegistrationPage';
const SignedInRoutePages = () => {
    //Firebase page routing... 
    const [educoPageRoutes, setEducoPageRoutes] = useState([]);
    const [communitiesPageRoutes, setCommunitiesPageRoutes] = useState([]);
    const [coursePageRoutes, setCoursePageRoutes] = useState([]);
    const [mentorsPageRoutes, setMentorsPageRoutes] = useState([]);

    useEffect(() => {
        const getRoutes = async () => {
            //fetch calls for firebase routes
            const educoRoutes = await fetchPageRoutes();
            const communityRoutes = await fetchCommunitiesRoutes();
            const mentorRoutes = await fetchMentorsRoutes();
            const courseRoutes = await fetchCourseRoutes();
            
            // setting routes
            setEducoPageRoutes(educoRoutes);
            //console.log('routes', educoRoutes)
            setCommunitiesPageRoutes(communityRoutes);
            setCoursePageRoutes(courseRoutes);
            setMentorsPageRoutes(mentorRoutes);
        };
        getRoutes(); 
    }, []);
    const [communities, setCommunities] = useState([]);
    const navigate = useNavigate();
  
    const handleCreateCommunity = (community) => {
      setCommunities([...communities, community]);
      navigate(`/community/${community.title}`);
    };
    
    //console.log(educoPageRoutes)

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/meettheteam" element={<About />} />
      <Route path="/ourtrustees" element={<OurTrustees />} />
      <Route path="/ourteam" element={<OurTeam />} />
      <Route path="/contactus" element={<ContactForm />} />
      <Route path="/donate" element={<Donate />} />
      <Route path="/events" element={<EventsPortal />}/>
      <Route path="/events/:eventId" element={<EventsTemplatePage />} />
      <Route path="/events/register/:eventId" element={<EventRegistrationPage />}/>
 
      <Route path="/education" element={<Education />} />
      <Route path="/education/enrol" element={<Enrol />} />
      <Route path="/volunteer" element={<Volunteer />} />
      <Route path="/volunteer/becomeanofficer" element={<BecomeAnOfficer />} />
      <Route path="/outreach" element={<Outreach />} />
      <Route path="/policies" element={<Policies />} />
      <Route path="/policies/:policyId" element={<Policies />} />
 
        {/*
        Statements Directory
      <Route path="/loading" element={<LoadingComponent isLoading={true} />} />
      */}
      <Route path="/statements" element={<Statements />} />
      <Route path="/statements/resourceLibrary/diversitystatement" element={<DiversityStatement />} />
      <Route path="/statements/resourceLibrary/edistatement" element={<EDICommitmentComponent />} />
      <Route path="/statements/resourceLibrary" element={<ResourceLibrary />} />
      <Route path="/statements/resourcelibrary/grievanceprocedure" element={<GrievanceProcedure />} />
      <Route path="/statements/resourcelibrary/grievanceform" element={<GrievanceForm />} />
      <Route path="/statements/resourcelibrary/charityhelplines" element={<MentalHealthCharitiesGuide />} />
      <Route path="/statements/resourcelibrary/whistleblowingpolicyguide" element={<WhistleblowingPolicyGuide />} />

      {/*
        Sign and Registration Pages
        */}
      <Route path="/signIn" element={<SignInComponent />} />
      <Route path="/forgotPassword" element={<ForgotPasswordComponent />} />
      <Route path="/createaccount" element={<CreateAccountComponent />} />
      <Route path="/createauserprofile" element={<CreateAUserProfileComponent />} />
      <Route path="/uploadingprofile" element={<UploadingProfile />} />

      {/* Educo routes */}
      <Route path="/explore" element={<ExploreComponent />} />
      <Route path="/educo" element={<Comingsoon />}/> 
      {/* element=<ExploreComponent */}

      {/* Need to add if not enrolled in course routes, if not show coursepage */}
      <Route path="/profile/*" element={<Comingsoon />} />
      {/* element=<ProfilePage */}
      <Route path="/courses" element={<Comingsoon />} />
      {/* element=<CourseExplorePage */}

      <Route path="/course/:courseId" element={<Comingsoon />} />
      {/* element=<EnrolledCoursePage */}

      <Route path="/course/:courseId/:moduleId/:lessonId" element={<Comingsoon />} />
      {/* element=<CourseVideoPage */}

      <Route path="/quizResult" element={<Comingsoon />} />
      {/* element=<QuizResult */}

      {/* Mentor routes */}
      <Route path="/mentors" element={<MentorsExplorePage />} />
      {/* element=<Comingso */}

      <Route path="/mentors/mentorprogammepage" element={<MentorProgrammePage />} />
      {/* element=<MentorProgrammePage */}
      <Route path="/mentors/mentor-request" element={<ExploreComponent />} />

      <Route path="/mentors/mentorprogammepage/sessionfeedbackform" element={<MentoringFeedbackForm />} />
      {/* element=<MentoringFeedbackForm */}

      {/* <Route path="/communities" element={<CommunitiesExplorePage />} />
      <Route
          path="/createcommunity"
          element={<CreateCommunity onCreate={handleCreateCommunity} />}
        />
        <Route path="/communities/c/:communityName" element={<CommunitiesExplorePage />} />
        <Route path="/communities/c/:communityName/post/:postId" element={<CommunitiesExplorePage />} /> */}
        <Route path="/communities" element={<Comingsoon />} />
        <Route
          path="/createcommunity"
          element={<Comingsoon />}
        />
        <Route path="/communities/c/:communityName" element={<Comingsoon />} />
        <Route path="/communities/c/:communityName/post/:postId" element={<Comingsoon />} />

      {/* News Articles */}
      <Route path="/news/:articleId" element={<NewsArticle />} />
      <Route path="/news" element={<News />} />

      <Route path="/admin" element={<AdminHomepage />} />
      {educoPageRoutes.map((route) => (
          <Route
            key={route.key}
            path={`/${route.route}`}
            element={<VideoPage name={`${route.title}`} />}
          />
        ))}

      <Route path='/admin/OfficerPortal' element={<AdminHomepage />} />
      <Route path='/admin/EmailFooterGenerator' element={<EmailFooterGenerator />} />
      <Route path="/${}" element={<StudioDashboard />} />
      {/*
        {communitiesPageRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<CommunitiesPage name={route.title} />}
          />
        ))}
        {coursePageRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<CoursePage name={route.title} />}
          />
        ))}
        {mentorsPageRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<MentorPage name={route.title} />}
          />
        ))}
        */
    }
      <Route path="*" element={<NotFound />} /> 
    </Routes>
  );
};

export default SignedInRoutePages;