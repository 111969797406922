import React from "react";
import { styles } from "../generalStyles";
import { outreachPageContent } from "./pageContentOutreach";

function Outreach() {
  return (
    <React.Fragment>
      <div style={styles.container}>
        <div style={styles.columnTwo}>
          <h1 id='outreachPageTitle'>
            {outreachPageContent.headerOne}
          </h1>
          <h2 id='outreachTeamMotto'>
            {outreachPageContent.teamVision}
          </h2>
          <p>
            {outreachPageContent.pageIntroduction}
          </p>
          <h2 id="whyOutreach">
            {outreachPageContent.headerTwo}
          </h2>
          <p>
            {outreachPageContent.paraOne}
          </p>
          <h2 id="partnerOrganisation">
            {outreachPageContent.headerThree}
          </h2>
          <p>
            {outreachPageContent.paraTwo}
          </p>
          <h2 id="beAPartner">
            {outreachPageContent.headerFour}
          </h2>
          <p>
            {outreachPageContent.paraThree}
          </p>
        </div>
        <div style={styles.columnOne}>
          <div style={styles.responsiveColumnOne}>
            <img src={''} 
                style={styles.imgAuthor}
                alt='Outreach team icon'></img>
            <h2>About the team:</h2>
            <p>
              The Outreach team is in charge....  of recruiting and managing
              short and long term volunteers for the Charity, whilst
              establishing partnerships with Universities across the UK. From
              their recruitment onwards, the team ensures that all volunteers
              enjoy their time with us and use their volunteering experience to
              grow personally and professionally.
            </p>
          </div>
          <div style={styles.responsiveColumnTwo}>
            <h4>
                Article quick links:
            </h4>
            <ul>
            <li style={styles.pageList}>
              <a 
                style={styles.pageListLink}
                href="#Volunteering-at-OTC">
                    {outreachPageContent.headerOne}
              </a>
            </li>
            <li style={styles.pageList}>
              <a 
                style={styles.pageListLink}
                href="#Volunteering-at-OTC">
                    {outreachPageContent.headerTwo}
              </a>
            </li>
            <li style={styles.pageList}>
              <a 
                style={styles.pageListLink}
                href="#Volunteering-at-OTC">
                    {outreachPageContent.headerThree}
              </a>
            </li>
            <li style={styles.pageList}>
              <a 
                style={styles.pageListLink}
                href="#Volunteering-at-OTC">
                    {outreachPageContent.headerFour}
              </a>
            </li>
            </ul>
          </div>
          <div style={styles.clearFix}></div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Outreach;
