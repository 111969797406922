import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import useAuth from '../../../../Firebase/Auth'; // Adjust import path as needed
import { useLocation } from 'react-router-dom';

const MentoringFeedbackForm = () => {
  const currentUser = useAuth();
  const [formData, setFormData] = useState({
    sessionEngagement: '',
    epqRelevance: '',
    focusAreas: '',
    nextSessionActions: '',
    additionalCatchup: '',
    progressRating: '',    // For mentor
    sessionFeedback: '',
    studentProgress: '',   // For mentor
    mentorApproach: ''    // For mentee
  });
  const [isMentor, setIsMentor] = useState(true); // You'll need to determine this based on your auth logic
  const location = useLocation();
  const { stage, stageName, userId, schoolId } = location.state || {};
  
  useEffect(() => {
    if (stage && stageName && userId) {
      console.log('session', stage, stageName, userId, schoolId);
    }
  }, [stage, stageName, userId, schoolId]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Add your Firebase function call here
      //console.log('Form submitted:', formData);
      alert('Feedback submitted successfully!');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Error submitting feedback');
    }
  };

  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    label: {
      fontWeight: 'bold',
      color: '#333',
    },
    select: {
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid #ddd',
      fontSize: '16px',
    },
    textarea: {
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid #ddd',
      minHeight: '100px',
      fontSize: '16px',
      resize: 'vertical',
    },
    button: {
      padding: '12px 24px',
      backgroundColor: '#1F484C',
      color: 'white',
      border: 'none',
      borderRadius: '24px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: '500',
    },
    title: {
      color: '#333',
      marginBottom: '20px',
    },
    description: {
      color: '#666',
      marginBottom: '30px',
    }
  };

  const engagementOptions = [
    'Very Engaging',
    'Somewhat Engaging',
    'Neutral',
    'Not Very Engaging',
    'Not Engaging at All'
  ];

  const epqRelevanceOptions = [
    'Very Relevant',
    'Somewhat Relevant',
    'Neutral',
    'Not Very Relevant',
    'Not Relevant at All'
  ];

  const yesNoOptions = ['Yes', 'No'];

  const progressOptions = [
    'Significant Improvement',
    'Some Improvement',
    'No Change',
    'Slight Decline',
    'Significant Decline'
  ];


  return (
    <div style={styles.container}>
      <h1 style={styles.title}>
        {isMentor ? 'Mentor-Mentee Connect Feedback' : 'Mentee-Mentor Connect Feedback'}
      </h1>
      <p style={styles.description}>
        Thank you for making time for the connect. We hope you found it useful! 
        We want to hear your feedback so we can make them more productive.
        Please fill this quick survey and let us know your thoughts.
      </p>

      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <label style={styles.label}>How engaging was the session?</label>
          <select
            name="sessionEngagement"
            value={formData.sessionEngagement}
            onChange={handleChange}
            style={styles.select}
            required
          >
            <option value="">Select Answer...</option>
            {engagementOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>
            How relevant and helpful was the session towards meeting the EPQ goals?
          </label>
          <select
            name="epqRelevance"
            value={formData.epqRelevance}
            onChange={handleChange}
            style={styles.select}
            required
          >
            <option value="">Select Answer...</option>
            {epqRelevanceOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>What were the focus areas covered in this session?</label>
          <textarea
            name="focusAreas"
            value={formData.focusAreas}
            onChange={handleChange}
            style={styles.textarea}
            required
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>
            What are the action items agreed to be covered for the next session?
          </label>
          <textarea
            name="nextSessionActions"
            value={formData.nextSessionActions}
            onChange={handleChange}
            style={styles.textarea}
            required
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>
            Are you planning to have additional catchup calls with the {isMentor ? 'Mentee' : 'Mentor'} before the next milestone?
          </label>
          <select
            name="additionalCatchup"
            value={formData.additionalCatchup}
            onChange={handleChange}
            style={styles.select}
            required
          >
            <option value="">Select Answer...</option>
            {yesNoOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>

        {isMentor && (
          <div style={styles.formGroup}>
            <label style={styles.label}>
              How will you rate the progress of Mentee compared to the last session?
            </label>
            <select
              name="progressRating"
              value={formData.progressRating}
              onChange={handleChange}
              style={styles.select}
              required
            >
              <option value="">Select Answer...</option>
              {progressOptions.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
        )}

        <div style={styles.formGroup}>
          <label style={styles.label}>
            Any other feedback/comments on the session?
          </label>
          <textarea
            name="sessionFeedback"
            value={formData.sessionFeedback}
            onChange={handleChange}
            style={styles.textarea}
          />
        </div>

        {isMentor && (
          <div style={styles.formGroup}>
            <label style={styles.label}>
              Any other feedback/comments on Mentee's progress?
            </label>
            <textarea
              name="studentProgress"
              value={formData.studentProgress}
              onChange={handleChange}
              style={styles.textarea}
            />
          </div>
        )}

        <motion.button
          type="submit"
          style={styles.button}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Submit Form
        </motion.button>
      </form>
    </div>
  );
};

export default MentoringFeedbackForm;