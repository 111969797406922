import React, { useState, useEffect } from 'react';
import { functions } from '../../../Firebase/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { BsFillGrid3X3GapFill, BsBellFill, BsGearFill } from 'react-icons/bs';
import { FaPeopleCarry } from "react-icons/fa";
import { FaBlog } from 'react-icons/fa';
import { IoNotifications } from 'react-icons/io5';
import { FiSettings } from 'react-icons/fi';
import { RiSchoolLine } from "react-icons/ri";

// components
import MentorProgrammeAdminSection from '../MentorComponents/MentorProgrammeAdminSection';
import EmailFooterGenerator from '../OfficerComponents/CreateFooter/CreateFooter';
import WelcomePack from '../OfficerComponents/WelcomePack/WelcomePack';
import JobDescription from '../OfficerComponents/JobDescription/JobDescription';
import CharityContract from '../OfficerComponents/CharityContract/CharityContract';
import CreateCharityProfile from '../OfficerComponents/CreateProfile/CreateCharityProfile';
import School from './School/School';
import Settings from './Settings/Settings';
import { EPQHomePage } from '../StudentComponents/EPQHomePage';
import EDIIncidentReportForm from '../OfficerComponents/IncidentForms/EDIIncidentForm';

import CharityInfo from './CharityInfo/CharityInfo';


import FirstAidPoster from '../OfficerComponents/FirstAiders/FirstAiders';
import useAuth from '../../../Firebase/Auth';
import { emailsHasher } from '../../../Firebase/educoFunctions';
import RecentUpdates from '../RecentUpdates/RecentUpdates';
import { CharityUpdates } from '../CharityUpdates/CharityUpdates';
import { color } from 'framer-motion';
// Import other necessary components
// Sidebar Component
// Sidebar Component

// Main AdminHomepage Component
const AdminHomepage = () => {
  const [activeSection, setActiveSection] = useState('CharityInfo');
  const [userRoles, setUserRoles] = useState({
    isMentor: false,
    isTeacher: false,
    isOfficer: false,
    hasEPQ: false
  });
  const [epqData, setEpqData] = useState(null);
  const currentUser = useAuth();

  useEffect(() => {
    const checkUserRoles = async () => {
      if (currentUser) {
        try {
          const emailHash = emailsHasher(currentUser.email);
          const getUserRoles = httpsCallable(functions, 'get_user_roles');
          const result = await getUserRoles({ userId: emailHash});
          //console.log(result)
          setUserRoles({
            isMentor: result.data.data.isMentor,
            isTeacher: result.data.data.isTeacher,
            isOfficer: result.data.data.isOfficer,
            hasEPQ: result.data.data.hasEPQ
          });
          if (result.data.data.hasEPQ) {
            //console.log(result.data.data)
            setEpqData(result?.data.data.EPQData);
          }

          
          // Set initial active section based on roles
          if (result.data.data.isOfficer) setActiveSection('CharityInfo');
          else if (result.data.data.isTeacher) setActiveSection('CharityInfo');
          else if (result.data.data.isMentor) setActiveSection('CharityInfo');
          else if (result.data.data.hasEPQ) setActiveSection('CharityInfo');
        } catch (error) {
          //console.error("Error checking user roles:", error);
        }
      }
    };

    checkUserRoles();
  }, [currentUser]);


  const handleSidebarItemClick = (section) => {
    setActiveSection(section);
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'CharityInfo':
        return <CharityInfo />;
      case 'Orienteering':
        return <Orienteering />;
      case 'MentorProgramme':
        return <MentorProgrammeAdminSection epqData={epqData} />;
      case 'Blog':
        return <Blog />;
      case 'Settings':
        return <Settings />;
        case 'School':
          return <School />;
          case 'EPQ':
            return <EPQHomePage epqData={epqData} />;
      default:
        return <CharityInfo />;
    }
  };


  return (
    <div style={styles.container}>
      <div>
        <h1>
          Admin portal
        </h1>
      </div>
      <div style={styles.contentWrapper}>
        <Sidebar 
            activeSection={activeSection} 
            onSectionClick={handleSidebarItemClick}
            userRoles={userRoles}
            epqData={epqData}
          />
        <div style={styles.mainContent}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

const Sidebar = ({ activeSection, onSectionClick, userRoles, epqData }) => (
  <div style={styles.sidebar}>
    <SidebarItem
      icon={<BsBellFill />}
      text="Charity Info"
      isActive={activeSection === 'CharityInfo'}
      onClick={() => onSectionClick('CharityInfo')}
    />
    {/*<SidebarItem
      icon={<FaBlog />}
      text="Our Charity Blog"
      isActive={activeSection === 'Blog'}
      onClick={() => onSectionClick('Blog')}
    />*/}
    {userRoles.isOfficer && (
      <SidebarItem
        icon={<BsFillGrid3X3GapFill />}
        text="Orienteering"
        isActive={activeSection === 'Orienteering'}
        onClick={() => onSectionClick('Orienteering')}
      />
    )}
    {userRoles.isMentor && (
      <SidebarItem
        icon={<FaPeopleCarry />}
        text="Mentor Programme"
        isActive={activeSection === 'MentorProgramme'}
        onClick={() => onSectionClick('MentorProgramme')}
      />
    )}
    {userRoles.isTeacher && (
      <SidebarItem
        icon={<RiSchoolLine />}
        text="School"
        isActive={activeSection === 'School'}
        onClick={() => onSectionClick('School')}
      />
    )}
    {userRoles.hasEPQ && (
      <SidebarItem
        icon={<FaPeopleCarry />}
        text={`EPQ: ${epqData?.topicChosen}`}
        isActive={activeSection === 'EPQ'}
        onClick={() => onSectionClick('EPQ')}
      />
    )}
   {/* <SidebarItem
      icon={<FiSettings />}
      text="Settings"
      isActive={activeSection === 'Settings'}
      onClick={() => onSectionClick('Settings')}
    />*/}
  </div>
);

const SidebarItem = ({ icon, text, isActive, onClick }) => (
  <div
    style={{
      ...styles.sidebarItem,
      ...(isActive ? styles.activeSidebarItem : {}),
    }}
    onClick={onClick}
  >
    <span style={styles.sidebarIcon}>{icon}</span>
    <span style={styles.sidebarText}>{text}</span>
  </div>
);

// Orienteering Component
const Orienteering = () => {
  const [selectedUpdate, setSelectedUpdate] = useState(null);
  

  const orienteeringUpdates = [
    { title: 'OTC Welcome pack', status: 'New task created', image: '🧭' },
    { title: 'Your job description', status: 'In progress', image: '🗺️' },
    { title: 'Your charity contract', status: 'In progress', image: '🗺️' },
    { title: 'Create your charity profile', status: 'In progress', image: '🗺️' },
    { title: 'Create your footer', status: 'In progress', image: '🗺️' },
    { title: 'Equality, Diversity and Inclusion Report Incident Form', status: '', image: '🗺️' },
    { title: 'First Aiders', status: '', image: '🗺️' },
  ];

  const CharityUpdatesList = [
    {
      title: 'Welcome pack',
      assignedBy: 'Orientation Team',
      dueDate: 'Next Monday',
      ongoingProjects: 'New member onboarding',
      urgencyLevel: 'High',
    },
    {
      title: 'Your job description',
      assignedBy: 'Marketing Team',
      dueDate: 'End of week',
      ongoingProjects: 'Website update',
      urgencyLevel: 'Medium',
    },
    {
      title: 'Your charity contract',
      assignedBy: 'Marketing Team',
      dueDate: 'End of week',
      ongoingProjects: 'Website update',
      urgencyLevel: 'Medium',
    },
    {
      title: 'Create your charity profile',
      assignedBy: 'Marketing Team',
      dueDate: 'End of week',
      ongoingProjects: 'Website update',
      urgencyLevel: 'Medium',
    },
    {
      title: 'Create your footer',
      assignedBy: 'Web Development Team',
      dueDate: 'Next Thursday',
      ongoingProjects: 'Website redesign',
      urgencyLevel: 'Low',
    },
    {
      title: 'Equality, Diversity and Inclusion Report Incident Form',
      assignedBy: 'Web Development Team',
      dueDate: 'Next Thursday',
      ongoingProjects: 'Website redesign',
      urgencyLevel: 'Low',
    },
    {
      title: 'First Aiders',
      assignedBy: 'Policy Team',
      dueDate: 'Next Thursday',
      ongoingProjects: 'Website redesign',
      urgencyLevel: 'Low',
    },
  ];

  const handleUpdateClick = (index) => {
    setSelectedUpdate(CharityUpdatesList[index]);
  };


  return (
    <div style={styles.columns}>
      <div style={styles.column}>
        <RecentUpdates
          title="Officer Resource Center"
          updates={orienteeringUpdates}
          onUpdateClick={handleUpdateClick}
        />
      </div>
      <div style={styles.column}>
        {selectedUpdate && selectedUpdate.title === 'Create your footer' ? (
          <EmailFooterGenerator />
        ) :
        selectedUpdate && selectedUpdate.title === 'Welcome pack' ? (
          <WelcomePack />
        ) :
        selectedUpdate && selectedUpdate.title === 'Your job description' ? (
          <JobDescription />
        ) :
        selectedUpdate && selectedUpdate.title === 'Your charity contract' ? (
          <CharityContract />
        ) :
        selectedUpdate && selectedUpdate.title === 'Create your charity profile' ? (
          <CreateCharityProfile />
        ) :
        selectedUpdate && selectedUpdate.title === 'Equality, Diversity and Inclusion Report Incident Form' ? (
          <EDIIncidentReportForm />
        ) :
        selectedUpdate && selectedUpdate.title === 'First Aiders' ? (
          <FirstAidPoster/>
        ) :
         (
          <>
          </>
        )}
      </div>
    </div>
  );
};

// CharityInfo Component


// Blog Component (placeholder)
const Blog = () => (
  <div style={styles.columns}>
    <div style={styles.column}>
      <h2>Blog Content</h2>
      <p>Blog content goes here...</p>
    </div>
  </div>
);

// Settings Component (placeholder)

// RecentUpdates Component

export const styles = {
    container: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
      },
      searchBar: {
        display: 'flex',
        marginBottom: '20px',
        backgroundColor: '#FAD4C0',
        padding: '10px',
        borderRadius: '5px',
      },
      searchInput: {
        flex: 1,
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
      },
      createTaskButton: {
        marginLeft: '10px',
        padding: '10px 15px',
        backgroundColor: '#FFE4B5',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      iconButton: {
        marginLeft: '10px',
        padding: '10px 15px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '20px',
      },
      viewButton: {
        marginLeft: '10px',
        padding: '10px 15px',
        backgroundColor: '#FFE4B5',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      contentWrapper: {
        display: 'flex',
        flex: 1,
      },
      sidebar: {
        width: '200px',
        backgroundColor: '#FCEDE6',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
      },
      sidebar: {
				width: '250px',
				padding: '20px',
				display: 'flex',
				flexDirection: 'column',
			},
			sidebarItem: {
				display: 'flex',
				alignItems: 'center',
				padding: '10px 0',
				cursor: 'pointer',
				transition: 'background-color 0.3s',
			},
			activeSidebarItem: {
				backgroundColor: '#1F484C',
        color: '#fff',
        borderRadius: '8px',
        padding: '10px'
			},
			sidebarIcon: {
				marginRight: '10px',
				fontSize: '20px',
			},
			sidebarText: {
				fontSize: '16px',
			},
			completeTraining: {
				marginTop: 'auto',
				padding: '10px',
				backgroundColor: '#FFE4B5',
				borderRadius: '5px',
				textAlign: 'center',
				cursor: 'pointer',
			},
			signOut: {
				marginTop: '10px',
				padding: '10px',
				textAlign: 'center',
				cursor: 'pointer',
			},
      completeTraining: {
        marginTop: 'auto',
        padding: '10px',
        backgroundColor: '#FFE4B5',
        borderRadius: '5px',
        textAlign: 'center',
        cursor: 'pointer',
      },
      signOut: {
        marginTop: '10px',
        padding: '10px',
        textAlign: 'center',
        cursor: 'pointer',
      },
      mainContent: {
        flex: 1,
        padding: '0 20px',
      },
      columns: {
        display: 'flex',
        gap: '20px',
      },
  column: {
    flex: 1,
  },
  recentUpdates: {
    backgroundColor: '#1F484C',
    color: '#fff',
    borderRadius: '5px',
    padding: '20px',
  },
  CharityUpdates: {
    backgroundColor: '#FAD4C0',
    borderRadius: '5px',
    padding: '20px',
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
  },
  sectionTitle: {
    fontSize: '18px',
    margin: 0,
  },
  ellipsis: {
    fontSize: '24px',
    cursor: 'pointer',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
  },
  updatesList: {
    display: 'flex',
    flexDirection: 'column',
  },
  updateItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    backgroundColor: '#FEFBF6',
    color: '#000',
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  selectedUpdateItem: {
    backgroundColor: '#F4A460', // Change this to your preferred highlight color
  },
  checkbox: {
    marginRight: '10px',
  },
  updateContent: {
    flex: 1,
  },
  updateTitle: {
    fontWeight: 'bold',
  },
  updateStatus: {
    fontSize: '14px',
    color: '#666',
  },
  updateImage: {
    fontSize: '24px',
    marginLeft: '10px',
  },
  updateDetails: {
    marginBottom: '20px',
  },
  detailItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  detailLabel: {
    fontWeight: 'bold',
  },
  detailValue: {
    backgroundColor: 'white',
    padding: '5px 10px',
    borderRadius: '5px',
  },
  urgencyBadge: {
    backgroundColor: '#FFE4B5',
    padding: '5px 10px',
    borderRadius: '5px',
  },
  subSectionTitle: {
    fontSize: '16px',
    marginBottom: '10px',
  },
  fileAttachment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  linkAttachment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '5px',
    marginBottom: '10px',
    border: '1px solid #ccc',
  },
  attachButton: {
    padding: '5px 10px',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  includeButton: {
    padding: '5px 10px',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '20px',
  },
  moveButton: {
    padding: '10px 15px',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  deleteButton: {
    padding: '10px 15px',
    backgroundColor: '#FFE4B5',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default AdminHomepage;