import React from 'react';

const BecomeAnOfficer = () => {
  const styles = {
    mainContainer: {
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    contentWrapper: {
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto'
    },
    heading: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      color: '#1a1a1a',
      textAlign: 'center',
      marginBottom: '2rem',
      lineHeight: '1.2'
    },
    formContainer: {
      borderRadius: '8px',
      overflow: 'hidden',
      padding: '1.5rem'
    },
    iframeWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '600px'
    },
    iframe: {
      width: '100%',
      height: '100%',
      minHeight: '600px',
      border: 'none',
      backgroundColor: 'transparent'
    },
    footerText: {
      fontSize: '1.125rem',
      color: '#666666',
      textAlign: 'center',
      marginTop: '1.5rem',
      fontStyle: 'italic'
    }
  };

  return (
    <>
      <head>
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.offthecurriculum.co.uk/become-an-officer"
        />
        <meta property="og:title" content="Career" />
        <meta property="og:description" content="#" />
        <meta property="og:image" content="#" />
        <meta property="og:image:secure_url" content="#" />
        <meta property="og:image:alt" content="#" />
      </head>
      
      <div style={styles.mainContainer}>
        <div style={styles.contentWrapper}>
          <h1 style={styles.heading}>
            Be a part of history and apply to be an Officer at Off the Curriculum
            today!
          </h1>
          
          <div style={styles.formContainer}>
            <div style={styles.iframeWrapper}>
              <iframe
                src="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfJpVx50dWKPN0gT1cDcrAysp3bffAiZDa5DLiPq9KBnErAbg/viewform?embedded=true"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                style={styles.iframe}
                title="volunteer sign up form"
              >
                Loading…
              </iframe>
            </div>
          </div>

          <p style={styles.footerText}>
            Join us in shaping the future of education
          </p>
        </div>
      </div>
    </>
  );
};

export default BecomeAnOfficer;