// EventRegistrationPage.jsx
import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CiClock1 } from "react-icons/ci";
import { IoLocationOutline, IoTicketOutline } from "react-icons/io5";
import EventRegistrationForm from './Components/EventRegistration';
import * as EventsData from './data/index'

const EventRegistrationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { eventDetails } = location.state || {};
  const { eventId } = useParams();
  const [currentEvent, setCurrentEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const eventData = EventsData[`${eventId}Event`];
        
        if (eventData) {
          setCurrentEvent(eventData);
        } else {
          setError('Event not found');
          navigate('/events');
        }
      } catch (err) {
        setError('Error loading event details');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventId, navigate]);

  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '2rem',
    },
    header: {
      marginBottom: '2rem',
    },
    eventSummary: {
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      padding: '2rem',
      marginBottom: '2rem',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      color: '#1F484C',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      marginBottom: '1rem',
      fontSize: '1rem',
    },
    eventImage: {
      width: '100%',
      height: '300px',
      objectFit: 'cover',
      borderRadius: '8px',
      marginBottom: '1.5rem',
      objectPosition: 'center top', // This ensures the top of the image is always visible
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    },
    title: {
      fontSize: '2rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
    },
    metaInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      fontSize: '0.9rem',
      marginBottom: '1.5rem',
      flexWrap: 'wrap',
    },
    metaItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    divider: {
      width: '4px',
      height: '4px',
      borderRadius: '50%',
    },
    formContainer: {
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      padding: '2rem',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    },
    errorMessage: {
      color: '#e53e3e',
      textAlign: 'center',
      padding: '2rem',
    },
    loadingMessage: {
      textAlign: 'center',
      padding: '2rem',
      color: '#2C5282',
    }
  };

  if (loading) {
    return <div style={styles.loadingMessage}>Loading event details...</div>;
  }

  if (error) {
    return <div style={styles.errorMessage}>{error}</div>;
  }

  if (!currentEvent) {
    return <div style={styles.errorMessage}>Event not found</div>;
  }

  return (
    <div style={styles.container}>
      <button 
        onClick={() => navigate(`/events/${eventId}`)} 
        style={styles.backButton}
      >
        ← Back to event details
      </button>

      <div style={styles.eventSummary}>
        <img 
          src={currentEvent.imageSrc} 
          alt={currentEvent.title} 
          style={styles.eventImage}
        />
        <h1 style={styles.title}>{currentEvent.title}</h1>
        
        <div style={styles.metaInfo}>
          <div style={styles.metaItem}>
            <CiClock1 />
            <span>{currentEvent.time}</span>
          </div>
          <div style={styles.divider} />
          <div style={styles.metaItem}>
            <IoLocationOutline />
            <span>{currentEvent.location}</span>
          </div>
          <div style={styles.divider} />
          <div style={styles.metaItem}>
            <IoTicketOutline />
            <span>{currentEvent.ticketNumber} spots remaining</span>
          </div>
        </div>
      </div>

      <div style={styles.formContainer}>
        <EventRegistrationForm 
          eventDetails={{
            id: eventId,
            title: currentEvent.title,
            time: currentEvent.time,
            location: currentEvent.location,
            ticketNumber: currentEvent.ticketNumber
          }} 
        />
      </div>
    </div>
  );
};

export default EventRegistrationPage;