import React, { useState, useRef, useEffect} from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { emailsHasher } from '../../../../Firebase/educoFunctions';
import { functions } from '../../../../Firebase/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { ref } from 'firebase/storage';
import { fbStorage } from '../../../../Firebase/firebaseConfig';
import { uploadString, getDownloadURL } from 'firebase/storage';
import useAuth from '../../../../Firebase/Auth';
import StandardButtonContainer from '../../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import { updateCurrentUser } from 'firebase/auth';
import { auth } from '../../../../Firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
// Define all styles at the top level
const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  section: {
    marginBottom: '20px',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  textArea: {
    width: '95%',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',
    height: '120px', 
    maxHeight: '120px',
    resize: 'none', 
    overflowY: 'auto', 
    margin: 'auto',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  disclaimer: {
    fontSize: '14px',
    color: '#666',
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#f8f8f8',
    borderRadius: '4px',
  },
  fileInputContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '6px 12px',
    cursor: 'pointer',
    backgroundColor: '#fff',
  },
  fileInputText: {
    marginRight: '10px',
    color: '#666',
  },
  fileInputButton: {
    backgroundColor: '#f0f0f0',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '6px 12px',
    fontSize: '14px',
    cursor: 'pointer',
  },
  hiddenFileInput: {
    display: 'none',
  },
  // Preview styles
  previewContainer: {
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  },
  previewContent: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  profileHeader: {
    display: 'flex',
    gap: '20px',
    marginBottom: '30px',
  },
  previewImage: {
    width: '250px',
    height: '250px',
    borderRadius: '10px',
    objectFit: 'cover',
  },
  profileInfo: {
    flex: 1,
  },
  previewSection: {
    marginBottom: '20px',
  },
  previewActions: {
    display: 'flex',
    gap: '15px',
    justifyContent: 'center',
    marginTop: '30px',
  },
  editButton: {
    padding: '10px 20px',
    backgroundColor: '#666',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  submitButton: {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  dropdown: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    fontSize: '16px'
  },
  radioInput: {
    cursor: 'pointer',
    width: '18px',
    height: '18px'
  }
};

const CreateMentorProfile = () => {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [aboutMe, setAboutMe] = useState('');
  const [myName, setMyName] = useState('');

  const [linkedin, setLinkedin] = useState('');
  const [email, setEmail] = useState('');
  const [specialties, setSpecialties] = useState('');
  const [languages, setLanguages] = useState('');
  const [teachingMethods, setTeachingMethods] = useState('');
  const [availableDates, setAvailableDates] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [mentorType, setMentorType] = useState('');
  const cropperRef = useRef(null);
  const fileInputRef = useRef(null);
  const currentUser = useAuth();
  const [isAvailable, setIsAvailable] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  {/*useEffect(() => {
    if (currentUser) {
      const userId = emailsHasher(currentUser.email);
  
      // If you need to check the ID for some reason, you can keep the condition:
      if (userId === -241387881) {
        const user = auth.currentUser;
        const uid = user.uid;
        alert('User ID: ' + uid);
      }
      if (userId === 429087162) {
        const user = auth.currentUser;
        const uid = user.uid;
        alert('User ID: ' + uid);
      }
    } else {
    }
  }, [currentUser]);*/}
  

  // Cropper options for square profile photo
  const cropperOptions = {
    viewMode: 1,
    aspectRatio: 1,
    minCropBoxWidth: 250,
    minCropBoxHeight: 250,
    cropBoxResizable: false,
    data: { 
      width: 250,
      height: 250
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCroppedImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleAboutMeChange = (e) => {
    const text = e.target.value;
    if (text.split(/\s+/).length <= 150) {
      setAboutMe(text);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const validateForm = () => {
    const isValid = 
      croppedImage &&
      myName.trim() &&
      aboutMe.trim() &&
      specialties.trim() &&
      languages.trim() &&
      teachingMethods.trim() &&
      availableDates.trim() &&
      email.trim();
    
    setIsFormValid(isValid);
    return isValid;
  };

  const handlePreview = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowPreview(true);
    } else {
      alert('Please fill in all required fields and crop your profile photo.');
    }
  };
  
  const handleSubmit = async () => {
    setIsSubmitting(true);  // Set this at the start
    try {
      const userId = emailsHasher(currentUser?.email)
      const imageRef = ref(fbStorage, `mentor_programme/mentor_profiles/${userId}/${Date.now()}_profile.jpg`);
      const imageData = croppedImage.split(',')[1];
      
      await uploadString(imageRef, imageData, 'base64');
      const imageUrl = await getDownloadURL(imageRef);
  
      const mentorData = {
        username: userId,
        name: myName,
        bio: aboutMe,
        specialties: specialties,
        languages: languages,
        teachingMethods: teachingMethods,
        availableDates: availableDates,
        linkedin: linkedin,
        email: email,
        isAvailable: isAvailable === 'yes' ? 'True' : 'False',
        profileImage: imageUrl,
        mentorType: mentorType,
      };
  
      const submitProfile = httpsCallable(functions, 'submit_mentor_profile_information');
      const result = await submitProfile(mentorData);
  
      if (result.data.success) {
        alert('Profile submitted successfully! Your profile will appear on our available mentors page. Close this alert to redirect your browser!');
        // Optionally redirect or clear form
        navigate('/mentors')
      } else {
        throw new Error(result.data.error || 'Failed to submit profile');
      }
    } catch (error) {
      console.log(error);
      alert(`Error submitting profile: ${error.message}`);
    } finally {
      setIsSubmitting(false);  // Make sure this always runs
    }
  };

  const ProfilePreview = () => (
    
    <div style={styles.previewContainer}>
      <h2>Preview Your Profile</h2>
      <div style={styles.previewContent}>
        <div style={styles.profileHeader}>
          <img 
            src={croppedImage} 
            alt="Profile" 
            style={styles.previewImage}
          />
          <div style={styles.profileInfo}>
            <h3>About Me</h3>
            <p>{aboutMe}</p>
          </div>
        </div>

        <div style={styles.previewSection}>
          <h3>Specialties</h3>
          <p>{specialties}</p>
        </div>

        <div style={styles.previewSection}>
          <h3>Languages</h3>
          <p>{languages}</p>
        </div>
        
        <div style={styles.previewSection}>
          <h3>Availability</h3>
          <p>Available to start immediately: {isAvailable === 'yes' ? 'Yes' : 'No'}</p>
        </div>

        <div style={styles.previewSection}>
          <h3>Teaching Methods</h3>
          <p>{teachingMethods}</p>
        </div>

        <div style={styles.previewSection}>
          <h3>Available Dates</h3>
          <p>{availableDates}</p>
        </div>

        <div style={styles.previewSection}>
          <h3>Contact Information</h3>
          <p>Email: {email}</p>
          {linkedin && <p>LinkedIn: {linkedin}</p>}
        </div>

        <div style={styles.previewActions}>
          <StandardButtonContainer.ButtonOne 
            onClick={() => setShowPreview(false)}>
            Edit Profile
          </StandardButtonContainer.ButtonOne>
          <StandardButtonContainer.ButtonTwo
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting your data...' : 'Submit'}
          </StandardButtonContainer.ButtonTwo>
        </div>
      </div>
    </div>
  );

  if (showPreview) {
    return <ProfilePreview />;
  }

  return (
    <div style={styles.container}>
      <h1>Create Charity Mentor Profile</h1>
      <div style={styles.disclaimer}>
        <strong>Disclaimer:</strong> The information you provide, including your profile photo and about me section, will be posted on the mentor section of our website (See <Link to={'/mentors'}>here</Link>). Please ensure that you are comfortable with this information being 
        shared before submitting your profile. You can edit your profile information at any time after submission.
      </div>
      <form onSubmit={handlePreview} style={styles.form}>
        <div style={styles.section}>
          <h2>Profile Photo</h2>
          <div style={styles.fileInputContainer} onClick={triggerFileInput}>
            <span style={styles.fileInputText}>
              {image ? '1 file selected' : 'No attached files'}
            </span>
            <span style={styles.fileInputButton}>Attach file</span>
            <input 
              type="file" 
              ref={fileInputRef}
              onChange={handleImageChange} 
              accept="image/*" 
              style={styles.hiddenFileInput}
            />
          </div>
          {image && (
            <>
              <Cropper
                ref={cropperRef}
                style={{ height: 400, width: '100%', marginTop: '10px' }}
                zoomTo={0.5}
                src={image}
                {...cropperOptions}
              />
              <StandardButtonContainer.ButtonOne
              onClick={getCropData}
              style={{marginTop: '10px'}}
              >
                Crop Image (250x250)
              </StandardButtonContainer.ButtonOne>
            </>
          )}
          {croppedImage && (
            <div style={{marginTop: '20px'}}>
              <h3>Cropped Image Preview</h3>
              <img 
                src={croppedImage} 
                alt="Cropped" 
                style={{ width: '250px', height: '250px', objectFit: 'cover' }} 
              />
            </div>
          )}
        </div>

        <div style={styles.section}>
          <h2>Your Name</h2>
          <textarea 
            value={myName} 
            onChange={(e) => setMyName(e.target.value)} 
            placeholder="i.e. Tim Siu"
            style={styles.textArea}
          />
          <h2>About Me</h2>
          <textarea 
            value={aboutMe} 
            onChange={handleAboutMeChange} 
            placeholder="Tell us about yourself (max 150 words)"
            style={styles.textArea}
          />
          <p>Word count: {aboutMe.split(/\s+/).filter(Boolean).length}/150</p>
        </div>

        <div style={styles.section}>
          <h2>Mentor Type</h2>
          <select
            value={mentorType}
            onChange={(e) => setMentorType(e.target.value)}
            style={styles.dropdown}
            required
          >
            <option value="">Select Mentor Type</option>
            <option value="humanities">Humanities</option>
            <option value="stem">STEM</option>
          </select>
        </div>
        <div style={styles.section}>
          <h2>Are you available to start mentoring immediately?</h2>
          <div style={styles.radioGroup}>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="availability"
                value="yes"
                checked={isAvailable === 'yes'}
                onChange={(e) => setIsAvailable(e.target.value)}
                style={styles.radioInput}
              />
              Yes
            </label>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="availability"
                value="no"
                checked={isAvailable === 'no'}
                onChange={(e) => setIsAvailable(e.target.value)}
                style={styles.radioInput}
              />
              No
            </label>
          </div>
        </div>


        <div style={styles.section}>
          <h2>Specialties</h2>
          <p>Please space your specialties using: a semi-colon (;), i.e. Physics; Art;</p>
          <textarea 
            value={specialties} 
            onChange={(e) => setSpecialties(e.target.value)} 
            placeholder="List your areas of expertise (e.g., Physics, Toxicology, Biology)"
            style={styles.textArea}
          />
        </div>

        <div style={styles.section}>
          <h2>Languages</h2>
          <p>Please space your languages using: a semi-colon (;), i.e. English; French;</p>
          <textarea 
            value={languages} 
            onChange={(e) => setLanguages(e.target.value)} 
            placeholder="List the languages you speak (e.g., English, Français, Español)"
            style={styles.textArea}
          />
        </div>

        <div style={styles.section}>
          <h2>Teaching Methods</h2>
          <textarea 
            value={teachingMethods} 
            onChange={(e) => setTeachingMethods(e.target.value)} 
            placeholder="Describe your teaching methods and approach"
            style={styles.textArea}
          />
        </div>

        <div style={styles.section}>
          <h2>Available Dates for Induction Call</h2>
          <textarea 
            value={availableDates} 
            onChange={(e) => setAvailableDates(e.target.value)} 
            placeholder="List your available dates for an induction call"
            style={styles.textArea}
          />
        </div>

        <div style={styles.section}>
          <h2>Social Links</h2>
          <input 
            type="text" 
            value={linkedin} 
            onChange={(e) => setLinkedin(e.target.value)} 
            placeholder="LinkedIn URL"
            style={{...styles.input, marginTop: '10px'}}
          />
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="Email Address"
            style={{...styles.input, marginTop: '10px'}}
          />
        </div>
        <StandardButtonContainer.ButtonOne 
          type="button" 
          onClick={handlePreview} >
            Preview
        </StandardButtonContainer.ButtonOne>
      </form>

    </div>
  );
};

export default CreateMentorProfile;