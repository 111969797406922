import React, { useState, useEffect } from 'react';
import { functions } from '../../../../Firebase/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { emailsHasher } from '../../../../Firebase/educoFunctions';
import useAuth from '../../../../Firebase/Auth';
import StandardButtonContainer from '../../../../Components/Common/Buttons/StandardButtons/StandardButtons';

const School = () => {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [schoolData, setSchoolData] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsError, setDetailsError] = useState(null);

  const currentUser = useAuth();
  // Fetch students data
  useEffect(() => {
    const fetchSchoolStudents = async () => {
      if (currentUser) {
        try {
          console.log('hello')
          setLoading(true);
          const hashed = emailsHasher(currentUser.email);
          console.log(hashed)
          const getSchoolStudents = httpsCallable(functions, 'fetch_school_students');
          const result = await getSchoolStudents({ userId: hashed });
          console.log(result)
          if (result.data.success) {
            setStudents(result.data.students);
            setSchoolData(result.data.schools); 
          } else {
            throw new Error(result.data.error);
          }
          setLoading(false);
        } catch (err) {
          console.error("Error fetching students:", err);
          setError("Failed to load student data. Please try again later.");
          setLoading(false);
        }
      }
    };

    if (currentUser?.email) {
      fetchSchoolStudents();
    }
  }, [currentUser]);

  const getStageColor = (stage) => {
    const colors = {
      'Research': '#E3F2FD',
      'Planning': '#E8F5E9',
      'Implementation': '#FFF3E0',
      'Complete': '#E8EAF6',
      // Add more stages as needed
    };
    return colors[stage] || '#F5F5F5';
  };
  const getRatingColor = (rating) => {
    const colors = {
      'Improved': '#4CAF50',
      'Maintained': '#2196F3',
      'Needs Improvement': '#FFC107',
      // Add more ratings as needed
    };
    return colors[rating] || '#9E9E9E';
  };

const handleSelectStudent = async (student) => {
  try {
    setDetailsLoading(true);
    setDetailsError(null); // Clear any previous errors
    
    const getStudentDetails = httpsCallable(functions, 'fetch_student_details');
    const result = await getStudentDetails({
      userId: emailsHasher(currentUser.email),
      studentId: student.id
    });
    //console.log('result', result)
    if (result.data.success) {
      //console.log(result.data.students)
      setSelectedStudent(result.data.students);
    } else {
      throw new Error(result.data.error);
    }
  } catch (err) {
    //console.error("Error fetching student details:", err);
    setDetailsError("Failed to load student details. Please try again later.");
    // Don't clear selectedStudent, let user try again
  } finally {
    setDetailsLoading(false);
  }
};
const handleMessageClick = () => {
  alert("Message feature coming soon!");  // Basic alert
  // Or use a more styled toast notification if you have one in your project
};

  const styles = {
    container: {
      padding: '20px',
      maxWidth: '1400px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      minHeight: 'calc(100vh - 100px)',
    },
    topSection: {
      display: 'flex',
      gap: '20px',
      marginBottom: '20px',
    },
    schoolCard: {
      flex: 1,
      backgroundColor: 'white',
      borderRadius: '16px',
      padding: '24px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    },
    schoolTitle: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '20px',
      color: '#2D3748',
    },
    schoolInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    infoItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
    infoLabel: {
      fontSize: '14px',
      color: '#718096',
    },
    infoValue: {
      fontSize: '16px',
      color: '#2D3748',
      fontWeight: '500',
    },
    divider: {
      height: '1px',
      backgroundColor: '#E2E8F0',
      margin: '16px 0',
    },
    metricsCard: {
      flex: '1',
      padding: '25px',
      backgroundColor: 'white',
      borderRadius: '12px',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      maxWidth: '300px',
    },
    metric: {
      textAlign: 'center',
      padding: '15px',
      marginBottom: '15px',
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
    },
    metricBox: {
      padding: '16px',
      borderRadius: '8px',
      textAlign: 'center',
    },
    metricValue: {
      fontSize: '36px',
      fontWeight: 'bold',
      color: '#1F484C',
      marginBottom: '5px',
    },
    metricLabel: {
      color: '#666',
      fontSize: '14px',
    },
    studentsTable: {
      flex: '1',
      backgroundColor: 'white',
      borderRadius: '12px',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      overflow: 'hidden',
    },
    tableCard: {
      flex: 2,
      backgroundColor: 'white',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    },
    tableHeader: {
      display: 'grid',
      gridTemplateColumns: '1fr 100px 1fr 150px',
      padding: '15px 20px',
      backgroundColor: '#f8f9fa',
      borderBottom: '1px solid #dee2e6',
      fontWeight: 'bold',
    },
    tableRow: {
      display: 'grid',
      gridTemplateColumns: '1fr 100px 1fr 150px',
      padding: '12px 20px',
      borderBottom: '1px solid #eee',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: '#f8f9fa',
      },
    },
    selectedRow: {
      backgroundColor: '#FFE5CC',
      '&:hover': {
        backgroundColor: '#FFD6B3',
      },
    },
    detailsSection: {
      backgroundColor: 'white',
      borderRadius: '12px',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      padding: '25px',
      marginTop: '20px',
    },
    feedbackGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gap: '20px',
      marginTop: '20px',
    },
    feedbackCard: {
      padding: '20px',
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      border: '1px solid #eee',
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '300px',
    },
    loadingContent: {
      textAlign: 'center',
      color: '#666',
    },
    loadingSpinner: {
      width: '40px',
      height: '40px',
      margin: '0 auto 20px',
      border: '3px solid #f3f3f3',
      borderTop: '3px solid #4A90E2',
      borderRadius: '50%',
      animation: 'spin 1s linear infinite',
    },
    errorContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '300px',
    },
    errorContent: {
      textAlign: 'center',
      color: '#666',
    },
    retryButton: {
      marginTop: '20px',
      padding: '10px 20px',
      backgroundColor: '#4A90E2',
      color: 'white',
      border: 'none',
      borderRadius: '6px',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: '#357ABD',
      },
    },
    studentHeader: {
      padding: '24px',
      borderBottom: '1px solid #eee',
    },
    studentInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    studentMeta: {
      display: 'flex',
      gap: '12px',
    },
    gradeTag: {
      padding: '6px 12px',
      borderRadius: '16px',
      backgroundColor: '#1F484C',
      color: '#fff',
      fontSize: '14px',
    },
    mainGrid: {
      display: 'grid',
      gridTemplateColumns: '300px 1fr',
      gap: '24px',
      padding: '24px',
    },
    infoColumn: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
    },
    projectCard: {
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '12px',
      border: '1px solid #eee',
    },
    mentorCard: {
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '12px',
      border: '1px solid #eee',
    },
    reportsColumn: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    reportsTimeline: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    reportCard: {
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #eee',
      overflow: 'hidden',
    },
    reportHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px',
      backgroundColor: '#F8F9FA',
      borderBottom: '1px solid #eee',
    },
    reportDate: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dateDay: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#2D3748',
    },
    dateMonth: {
      fontSize: '14px',
      color: '#718096',
    },
    ratingTag: {
      padding: '6px 12px',
      borderRadius: '16px',
      color: 'white',
      fontSize: '14px',
    },
    reportContent: {
      padding: '20px',
    },
    feedbackSection: {
      marginBottom: '24px',
    },
    feedbackGrid: {
      display: 'grid',
      gap: '16px',
      marginTop: '12px',
    },
    feedbackItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
    reportFooter: {
      marginTop: '16px',
      paddingTop: '16px',
      borderTop: '1px solid #eee',
    },
    catchupTag: {
      padding: '6px 12px',
      borderRadius: '16px',
      backgroundColor: '#E8F5E9',
      color: '#2E7D32',
      fontSize: '14px',
      display: 'inline-block',
    },
  };

  if (loading) {
    //console.log('hello',students)
    return <div style={{ textAlign: 'center', padding: '20px' }}>Loading students data...</div>;
  }

  if (error) {
    return <div style={{ color: 'red', padding: '20px' }}>{error}</div>;
  }

  return (
    <div style={styles.container}>
      {/* Top Row - School Info and Overview */}
      <div style={styles.topSection}>
        {/* School Information Card */}
        <div style={styles.schoolCard}>
          {schoolData ? (
            <>
              <h2 style={styles.schoolTitle}>{schoolData.name}</h2>
              <div style={styles.schoolInfo}>
                <div style={styles.infoItem}>
                  <span style={styles.infoLabel}>Location</span>
                  <span style={styles.infoValue}>{schoolData.location}</span>
                </div>
                <div style={styles.infoItem}>
                  <span style={styles.infoLabel}>Lead Teacher</span>
                  <span style={styles.infoValue}>{schoolData.teacher}</span>
                </div>
                <div style={styles.divider} />
                <div style={styles.metricsGrid}>
                  <div style={styles.metricBox}>
                    <div style={styles.metricValue}>{students.length}</div>
                    <div style={styles.metricLabel}>Students</div>
                  </div>
                  <div style={styles.metricBox}>
                    <div style={styles.metricValue}>
                      {new Set(students.map(s => s.project)).size}
                    </div>
                    <div style={styles.metricLabel}>Projects</div>
                  </div>
                  <div style={styles.metricBox}>
                    <div style={styles.metricValue}>
                      {(students.reduce((acc, s) => acc + Number(s.grade), 0) / students.length).toFixed(1)}
                    </div>
                    <div style={styles.metricLabel}>Avg. Grade</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div style={styles.loadingPlaceholder}>Loading school data...</div>
          )}
        </div>
  
        {/* Student Overview Table */}
        <div style={styles.tableCard}>
          <div style={styles.tableHeader}>
            <div>Student</div>
            <div>Grade</div>
            <div>Project</div>
            <div>Stage</div>
          </div>
          <div style={styles.tableBody}>
          {students.map((student) => {
            //console.log('student', student);
            return (
            <div
            key={student.id}
            style={{
            ...styles.tableRow,
            ...(selectedStudent?.id === student.id ? styles.selectedRow : {})
            }}
            onClick={() => handleSelectStudent(student)}
            >
            <div>{student.name}</div>
            <div>{student.grade}</div>
            <div>{student.project}</div>
            <div>
            <span style={{
            ...styles.stageTag,
            backgroundColor: getStageColor(student.stage)
            }}>
            {student.stage}
            </span>
            </div>
            </div>
            );
            })}
          </div>
        </div>
      </div>
  
      {/* Bottom Section - Detailed Student View */}
      <div style={styles.detailsCard}>
      {detailsLoading ? (
        <div style={styles.loadingContainer}>
          <div style={styles.loadingContent}>
            <div style={styles.loadingSpinner} />
            <p>Collecting student data...</p>
          </div>
        </div>
      ) : detailsError ? (
        <div style={styles.errorContainer}>
          <div style={styles.errorContent}>
            <h3>Error Loading Details</h3>
            <p>{detailsError}</p>
            <button 
              style={styles.retryButton}
              onClick={() => selectedStudent && handleSelectStudent(selectedStudent)}
            >
              Retry
            </button>
          </div>
        </div>
      ) : selectedStudent ? (
        <div style={styles.detailsContent}>
          {/* Student Header */}
          <div style={styles.studentHeader}>
            <div style={styles.studentInfo}>
              <h2>{selectedStudent.name}'s Progress Dashboard</h2>
              <div style={styles.studentMeta}>
                <span style={styles.gradeTag}>Grade {selectedStudent.grade}</span>
                <span style={{
                  ...styles.stageTag,
                  backgroundColor: getStageColor(selectedStudent.stage)
                }}>
                  {selectedStudent.stage}
                </span>
              </div>
            </div>
          </div>

          {/* Main Content Grid */}
          <div style={styles.mainGrid}>
            {/* Left Column - Project and Mentor Info */}
            <div style={styles.infoColumn}>
              <div style={styles.projectCard}>
                <h3>Project Details</h3>
                <div style={styles.projectContent}>
                  <h4>{selectedStudent.project}</h4>
                  <p>Current Stage: {selectedStudent.stage}</p>
                </div>
              </div>

              {selectedStudent && (
                <div style={styles.mentorCard}>
                  <h3>Mentor Information</h3>
                  <div style={styles.mentorContent}>
                    <h4>Name: {selectedStudent.mentor}</h4>
                    <span style={styles.mentorRole}>Academic Mentor</span>
                    <p style={styles.mentorBio}>Bio: {selectedStudent.mentorBio}</p>
                    <StandardButtonContainer>
                      <StandardButtonContainer.ButtonOne
                      onClick={(e) => {
                        e.preventDefault();
                        handleMessageClick();
                      }}>
                        Message 
                      </StandardButtonContainer.ButtonOne>
                    </StandardButtonContainer>
                  </div>
                </div>
              )}
            </div>

            {/* Right Column - Session Reports */}
            <div style={styles.reportsColumn}>
              <h3>Session Reports</h3>
              <div style={styles.reportsTimeline}>
                {selectedStudent.feedbackForms?.map((feedback, index) => (
                  <div key={feedback.id} style={styles.reportCard}>
                    <div style={styles.reportHeader}>
                      <div style={styles.reportDate}>
                        <span style={styles.dateDay}>
                          {new Date(feedback.date).toLocaleDateString('en-GB', { day: '2-digit' })}
                        </span>
                        <span style={styles.dateMonth}>
                          {new Date(feedback.date).toLocaleDateString('en-GB', { month: 'short' })}
                        </span>
                      </div>
                      <div style={styles.sessionRating}>
                        <span style={{
                          ...styles.ratingTag,
                          backgroundColor: getRatingColor(feedback.mentor.mentee_progress_rating)
                        }}>
                          {feedback.mentor.mentee_progress_rating}
                        </span>
                      </div>
                    </div>

                    <div style={styles.reportContent}>
                      {/* Student Feedback Section */}
                      <div style={styles.feedbackSection}>
                        <h3>Student Session Feedback</h3>
                        <div style={styles.feedbackGrid}>
                          <div style={styles.feedbackItem}>
                            <label><b>Engagement</b></label>
                            <span>{feedback.student.session_engagement}</span>
                          </div>
                          <div style={styles.feedbackItem}>
                            <label><b>Focus Areas</b></label>
                            <p>{feedback.student.focus_areas}</p>
                          </div>
                          <div style={styles.feedbackItem}>
                            <label><b>Action Items</b></label>
                            <p>{feedback.student.action_items}</p>
                          </div>
                        </div>
                      </div>

                      {/* Mentor Feedback Section */}
                      <div style={styles.feedbackSection}>
                        <h3>Mentor Session Feedback</h3>
                        <div style={styles.feedbackGrid}>
                          <div style={styles.feedbackItem}>
                            <label><b>Areas Covered</b></label>
                            <p>{feedback.mentor.focus_areas}</p>
                          </div>
                          <div style={styles.feedbackItem}>
                            <label><b>Progress Notes</b></label>
                            <p>{feedback.mentor.mentee_progress_feedback}</p>
                          </div>
                          <div style={styles.feedbackItem}>
                            <label><b>Next Steps</b></label>
                            <p>{feedback.mentor.action_items}</p>
                          </div>
                        </div>
                      </div>

                      <div style={styles.reportFooter}>
                        <span style={styles.catchupTag}>
                          {feedback.mentor.additional_catchup_needed === "Yes" ? 
                            "Additional catchup scheduled" : "No additional catchup needed"}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={styles.placeholderMessage}>
          <h3>Student Details</h3>
          <p>Select a student from the table above to view their detailed progress information</p>
        </div>
      )}
    </div>
    <style>{`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
  );
};

export default School;