import React, { useState, useEffect } from 'react';
import { 
  IoPersonOutline, 
  IoSchoolOutline, 
  IoPeopleOutline, 
  IoBookOutline, 
  IoMenuOutline, 
  IoCloseOutline,
  IoHourglassOutline, // For pending status
  IoCheckmarkCircleOutline // For accepted status
} from "react-icons/io5";
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const BrainIcon = ({ style }) => (
  <img
    src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fassets%2Fbrain-white.svg?alt=media&token=efd4867d-27d0-417f-a480-bd97502a4234"
    alt="brain"
    style={{
      ...style,
      width: window.innerWidth < 400 ? '30px' : '40px',
      height: window.innerWidth < 400 ? '30px' : '40px',
    }}
  />
);

const BottomNav = ({ isMenuOpen, setIsMenuOpen, mentorStatus }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const mobileBreakpoint = 1443;
  const smallScreenBreakpoint = 400;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (windowWidth > mobileBreakpoint) {
    return null;
  }

  const isSmallScreen = windowWidth < smallScreenBreakpoint;

  const styles = {
    container: {
      position: 'fixed',
      bottom: 10,
      left: 0,
      right: 0,
      backgroundColor: 'white',
      boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)',
      padding: isSmallScreen ? '12px' : '16px',
      width: '85%',
      margin: 'auto',
      borderRadius: '25px',
      zIndex: 10000,
      maxWidth: 500,
    },
    wrapper: {
      maxWidth: '500px',
      margin: '0 auto',
    },
    navItems: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    navItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: isSmallScreen ? '4px' : '8px',
      textDecoration: 'none',
    },
    iconWrapper: {
      backgroundColor: '#1F484C',
      padding: isSmallScreen ? '5px' : '12px',
      borderRadius: '50%',
      marginBottom: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: isSmallScreen ? '30px' : '16px',
      height: isSmallScreen ? '30px' : '16px',
    },
    exploreIconWrapper: {
      backgroundColor: '#FF6B00',
      padding: isSmallScreen ? '16px' : '12px',
      borderRadius: '50%',
      marginBottom: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: isSmallScreen ? '24px' : '35px',
      height: isSmallScreen ? '24px' : '35px',
      marginTop: isSmallScreen ? '-20px' : '-16px',
      boxShadow: '0 4px 12px rgba(255, 107, 0, 0.3)',
    },
    icon: {
      width: isSmallScreen ? '16px' : '24px',
      height: isSmallScreen ? '16px' : '24px',
      color: 'white',
    },
    exploreIcon: {
      width: isSmallScreen ? '16px' : '24px',
      height: isSmallScreen ? '16px' : '24px',
      color: 'white',
    },
    label: {
      fontSize: isSmallScreen ? '8px' : '12px',
      color: '#666',
      marginTop: '4px',
    },
    exploreLabel: {
      fontSize: isSmallScreen ? '12px' : '14px',
      color: '#FF6B00',
      marginTop: isSmallScreen ? '6px' : '8px',
      fontWeight: '600',
    },
  };
  const getMentorIcon = () => {
    if (!mentorStatus) return IoSchoolOutline;
    if (mentorStatus.hasMentor) return IoCheckmarkCircleOutline;
    if (mentorStatus.hasPendingRequest) return IoHourglassOutline;
    return IoSchoolOutline;
  };

  const getMentorLabel = () => {
    if (!mentorStatus) return "Mentor";
    if (mentorStatus.hasMentor) return "Mentoring";
    if (mentorStatus.hasPendingRequest) return "Pending";
    return "Mentor";
  };

  const getMentorPath = () => {
    if (!mentorStatus) return "/mentors";
    if (mentorStatus.hasMentor) return "/mentors/mentoringprogramme";
    if (mentorStatus.hasPendingRequest) return "/mentors/mentor-request";
    return "/mentors";
  };

  const getMentorIconStyle = (baseStyle) => {
    const style = { ...baseStyle };
    
    if (mentorStatus?.hasPendingRequest) {
      style.animation = 'pulse 2s infinite';
    }
    
    if (mentorStatus?.hasMentor) {
      style.backgroundColor = '#4CAF50'; // Green for active mentoring
    }
    
    return style;
  };

  const navItems = [
    { icon: IoPersonOutline, label: 'Profile', path: '/profile' },
    { 
      icon: getMentorIcon(), 
      label: getMentorLabel(), 
      path: getMentorPath(),
      customIconStyle: getMentorIconStyle,
      isMentor: true
    },
    { icon: BrainIcon, label: 'Explore', path: '/explore', isExplore: true },
    { icon: IoBookOutline, label: 'Course', path: '/courses' },
    { icon: IoPeopleOutline, label: 'Community', path: '/communities' },
    { 
      icon: isMenuOpen ? IoCloseOutline : IoMenuOutline, 
      label: 'Menu', 
      path: '#',
      isMenu: true 
    },
  ];


  return (
    <>
      <div style={styles.container}>
        <div style={styles.wrapper}>
          <div style={styles.navItems}>
            {navItems.map((item, index) => {
              const IconComponent = item.icon;
              const iconWrapperStyle = item.customIconStyle 
                ? item.customIconStyle(item.isExplore ? styles.exploreIconWrapper : styles.iconWrapper)
                : (item.isExplore ? styles.exploreIconWrapper : styles.iconWrapper);
              
              return (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  {item.isMenu ? (
                    <motion.div
                      style={{...styles.navItem, cursor: 'pointer'}}
                      onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                      <motion.div style={iconWrapperStyle}>
                        <IconComponent style={styles.icon} />
                      </motion.div>
                      <span style={styles.label}>{item.label}</span>
                    </motion.div>
                  ) : (
                    <Link to={item.path} style={styles.navItem}>
                      <motion.div
                        style={iconWrapperStyle}
                        whileHover={{
                          boxShadow: item.isExplore 
                            ? '0 6px 16px rgba(255, 107, 0, 0.5)'
                            : '0 6px 16px rgba(31, 72, 76, 0.4)',
                        }}
                      >
                        <IconComponent 
                          style={item.isExplore ? styles.exploreIcon : styles.icon} 
                        />
                      </motion.div>
                      <span style={item.isExplore ? styles.exploreLabel : styles.label}>
                        {item.label}
                      </span>
                    </Link>
                  )}
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomNav;