import React from 'react';
import { Link } from 'react-router-dom';
import { Articles } from './articles/news-page-content';
import StandardButtonContainer from '../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import { useNavigate } from 'react-router-dom';
const News = () => {
  const navigate = useNavigate();

  const handleNewsArticle = (articleId) => {
    //console.log(articleId)
    navigate(`/news/${articleId}`);
  };
  const styles = {
    mainContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '2rem',
    },
    header: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '3rem',
      color: '#1a1a1a'
    },
    newsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '2rem',
      width: '100%'
    },
    newsCard: {
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      overflow: 'hidden',
      transition: 'transform 0.3s ease',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    cardImage: {
      width: '100%',
      objectFit: 'cover',
    },
    cardContent: {
      padding: '1.5rem',
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    newsTitle: {
      fontSize: '1.25rem',
      fontWeight: '600',
      marginBottom: '1rem',
      color: '#1a1a1a',
      lineHeight: '1.4'
    },
    metaIcon: {
      width: '16px',
      height: '16px',
      opacity: '0.7'
    },
    newsExcerpt: {
      fontSize: '1rem',
      lineHeight: '1.6',
      color: '#444444',
      marginBottom: '1.5rem',
      flex: 1
    },
    readMoreButton: {
      display: 'inline-block',
      padding: '0.75rem 1.5rem',
      backgroundColor: '#2c5282',
      color: '#ffffff',
      textDecoration: 'none',
      borderRadius: '4px',
      fontWeight: '500',
      transition: 'background-color 0.3s ease',
      textAlign: 'center',
      marginTop: 'auto'
    },
    hoverEffect: {
      transform: 'translateY(-5px)',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
    },
    metaContainer: {
      margin: '1rem 0',
      color: '#666666',
      fontSize: '0.9rem',
    },
    metaItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      flexWrap: 'wrap'
    },
    metaSeparator: {
      margin: '0 0.5rem',
      color: '#999999'
    },
    metaText: {
      color: '#666666',
      whiteSpace: 'nowrap'
    }
  };
  //console.log(Articles)
  return (
    <div style={styles.mainContainer}>
      <h1 style={styles.header}>Recent News at Off the Curriculum</h1>
      
      <div style={styles.newsGrid}>
        {Articles.map((article, index) => (
          <div 
            key={index} 
            style={styles.newsCard}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'translateY(-5px)';
              e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'none';
              e.currentTarget.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
            }}
            onClick={() => handleNewsArticle(article.articlepagehref)}
          >
            <img
              src={article.imageSrc}
              alt={article.imageSrcAlt}
              style={styles.cardImage}
            />
            
            <div style={styles.cardContent}>
              <h3 style={styles.newsTitle}>{article.title}</h3>
              
              <div style={styles.metaContainer}>
                <div style={styles.metaItem}>
                  <span style={styles.metaText}>{article.date}</span>
                  <span style={styles.metaSeparator}>|</span>
                  <span style={styles.metaText}>{article.author}</span>
                  <span style={styles.metaSeparator}>|</span>
                  <span style={styles.metaText}>{article.time}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;