import { signInWithPopup, signInAnonymously, signInWithEmailAndPassword, signOut } from "firebase/auth";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { auth } from "./firebaseConfig";
import { useNavigate } from "react-router-dom";
import { 
  signInWithRedirect, 
  getRedirectResult, 
  GoogleAuthProvider 
} from 'firebase/auth';

const handleSignIn = async (auth, provider, navigate) => {
  //sign in with google   
  signInWithPopup(auth, provider)
  .then((result) => {
  navigate('/Explore')
  const credential = provider.credentialFromResult(result);
  const token = credential.accessToken;
  const user = result.user;
})
.catch((error) => {
  const errorCode = error.code;
  const errorMessage = error.message;
  //const email = error.customData.email;
});
}

const handleEmailAndPasswordSignIn = async (email, password, navigate) => { // Modified to take email and password as arguments
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Handle successful sign-in (e.g., redirect, update UI)
      //console.log("User signed in with email and password");
      navigate('/Explore');
    } catch (err) {
      const errorCode = err.code;
      const errorMessage = err.message;
      if (
        errorCode === 'auth/wrong-password' ||
        errorCode === 'auth/user-not-found' ||
        errorCode === 'auth/invalid-login-credentials'
      ) {
        alert('Incorrect email or password. Please try again.');
      } else {
        alert(errorMessage);
      }
    }
  };

  const handleSignOut = async (navigate) => {
    try {
      // Clear cached data
      // For example, if you are using localStorage or sessionStorage:
      localStorage.clear();
      sessionStorage.clear();
  
      // Sign out the user
      await signOut(auth);
  
      // After signing out, navigate to the homepage or login page
      navigate('/');
  
      // Optionally, you can add logic to pull down the correct UID
      // This would typically be done when the user signs back in
    } catch (error) {
      // Handle sign-out errors if necessary
      console.error("Sign out error:", error);
    }
  };
  

// form handlers - limiting bio to 160 characters
export const handleFormChange = (setFormData) => (e) => {
  const { name, value } = e.target;
  if (name === 'bio' && value.length > 160) return;
  setFormData((prev) => ({ ...prev, [name]: value }));
};

// form handlers - cropping cover and profile images
export const createHandleCrop = (setFormData, cropper, cropType, setShowCropper) => () => {
  if (cropper) {
    let cropWidth, cropHeight;
    if (cropType === 'cover') {
      cropWidth = 1628;
      cropHeight = 142.85;
    } else {
      cropWidth = cropHeight = 190;
    }

    const croppedCanvas = cropper.getCroppedCanvas({
      width: cropWidth,
      height: cropHeight,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    });

    if (croppedCanvas) {
      const croppedImage = croppedCanvas.toDataURL('image/jpeg');

      setFormData(prev => ({
        ...prev,
        [cropType === 'cover' ? 'coverPhoto' : 'displayPhoto']: croppedImage,
      }));
      setShowCropper(false);
    } else {
      // Handle error or fallback behavior if cropping fails
      //console.error('Failed to crop image');
    }
  }
};


export const createHandleFocus = (setFocusedField) => (e) => {
  setFocusedField(e.target.name);
};

export const createHandleBlur = (setFocusedField) => () => {
  setFocusedField(null);
};

export const handleFileChange = (e, setImageToCrop, setCropType, setShowCropper, type) => {
  const { files } = e.target;
  if (files && files[0]) {
    const reader = new FileReader();
    reader.onload = (event) => {
      setImageToCrop(event.target.result);
      setCropType(type);
      setShowCropper(true);
    };
    reader.readAsDataURL(files[0]);
  }
};


export {handleSignIn, handleEmailAndPasswordSignIn, handleSignOut} 