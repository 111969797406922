import React, { useState, useEffect } from 'react';
import { 
  FaBrain, 
  FaEllipsisH, 
  FaSmile, 
  FaEdit, 
  FaTrash,
  FaHeart,
  FaThumbsUp,
  FaThumbsDown,
  FaLaugh,
  FaExclamation,
  FaQuestion
} from 'react-icons/fa';
import { FaEllipsisV } from 'react-icons/fa';

const Message = ({ message, isUser, onEdit, onDelete, onReact }) => {
  const [showActions, setShowActions] = useState(false);
  const [showReactions, setShowReactions] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const [showVerticalMenu, setShowVerticalMenu] = useState(false)
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(message.text);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showVerticalMenu && !event.target.closest(`#message-${message.id}-menu`)) {
        setShowVerticalMenu(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showVerticalMenu, message.id]);

  const reactions = [
    { icon: FaHeart, name: 'love' },
    { icon: FaThumbsUp, name: 'like' },
    { icon: FaThumbsDown, name: 'dislike' },
    { icon: FaLaugh, name: 'haha' },
    { icon: FaExclamation, name: 'wow' },
    { icon: FaQuestion, name: 'question' }
  ];

  const handleReactionClick = (reaction) => {
    // Check if the user has already used this reaction
    const hasReaction = message.reactions?.includes(reaction);
    if (hasReaction) {
      // Remove the reaction
      onReact(message.id, reaction, 'remove');
    } else {
      // Add the reaction
      onReact(message.id, reaction, 'add');
    }
    setShowReactions(false);
  };
  // Add these handler functions:
  const handleEditStart = () => {
    setIsEditing(true);
    setEditedText(message.text);
    setShowActions(false);
  };

  const handleEditSave = () => {
    if (editedText.trim() && editedText !== message.text) {
      onEdit(message.id, editedText);
    }
    setIsEditing(false);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
    setEditedText(message.text);
  };
  

  return (
    <div
    style={{
      ...styles.messageWrapper,
      justifyContent: isUser ? 'flex-end' : 'flex-start'
    }}
    onMouseEnter={() => setIsHovering(true)}
    onMouseLeave={() => setIsHovering(false)}
  >
    {!isUser && (
      <div style={styles.avatarSmall}>
        <FaBrain style={styles.avatarIconSmall} />
      </div>
    )}
    
    <div style={styles.messageContainer}>
      {/* Quick Reactions Bar on Hover */}
      {isHovering && (
  <div 
    style={{
      ...styles.quickReactionsBar,
      left: isUser ? 'auto' : '50%',
      right: isUser ? '50%' : 'auto',
      transform: `translateX(${isUser ? '40%' : '-50%'})`,
    }}
  >
    {reactions.map(({ icon: Icon, name }) => {
      const isActive = message.reactions?.includes(name);
      return (
        <button
          key={name}
          onClick={() => handleReactionClick(name)}
          style={{
            ...styles.quickReactionButton,
            backgroundColor: isActive ? '#f0f2f5' : 'transparent',
            transform: isActive ? 'scale(1.1)' : 'scale(1)',
          }}
          title={isActive ? "Click to remove reaction" : "Click to add reaction"}
        >
          <Icon 
            size={16} 
            color={isActive ? '#1F484C' : '#65676B'} 
          />
        </button>
      );
    })}
      {isUser && (
      <div  
      style={styles.verticalMenuWrapper}
      id={`message-${message.id}-menu`}
      >
        <button 
          style={{
            ...styles.quickReactionButton,
            backgroundColor: showVerticalMenu ? '#FEFBF6' : 'transparent',
          }}
          onClick={() => setShowVerticalMenu(!showVerticalMenu)}
        >
          <FaEllipsisV 
            size={16} 
            color={showVerticalMenu ? '#1F484C' : '#65676B'} 
          />
        </button>
        
        {showVerticalMenu && (
          <div style={styles.verticalMenuOptions}>
            <button 
              style={styles.menuOption}
              onClick={() => {
                handleEditStart();
                setShowVerticalMenu(false);
              }}
            >
              <FaEdit style={styles.menuOptionIcon} />
              <span>Edit</span>
            </button>
            <button 
              style={styles.menuOption}
              onClick={() => {
                setShowDeleteConfirm(true);
                setShowVerticalMenu(false);
              }}
            >
              <FaTrash style={styles.menuOptionIcon} />
              <span>Remove</span>
            </button>
          </div>
        )}
      </div>
    )}
    </div>
  )}

      {/* Message Actions Button */}
      <div
        style={{
          ...styles.actionsContainer,
          right: isUser ? '100%' : 'auto',
          left: isUser ? 'auto' : '100%',
          marginRight: isUser ? '8px' : '0',
          marginLeft: isUser ? '0' : '8px',
        }}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        {showActions && (
          <div style={styles.actionsMenu}>
            <button
              style={styles.actionButton}
              onClick={() => setShowReactions(!showReactions)}
            >
              <FaSmile />
            </button>
            {isUser && (
              <>
                <button
                  style={styles.actionButton}
                  onClick={handleEditStart}
                >
                  <FaEdit />
                </button>
                <button
                  style={styles.actionButton}
                  onClick={() => setShowDeleteConfirm(true)}
                >
                  <FaTrash />
                </button>
              </>
            )}
          </div>
        )}
        {showActions && (
          <div style={styles.actionsDot}>
            <FaEllipsisH size={12} color="#65676B" />
          </div>
        )}
      </div>

      {/* Reactions Menu */}
      {showReactions && (
        <div
          style={{
            ...styles.reactionsMenu,
            right: isUser ? '100%' : 'auto',
            left: isUser ? 'auto' : '100%',
            marginRight: isUser ? '8px' : '0',
            marginLeft: isUser ? '0' : '8px',
          }}
        >
          {reactions.map(({ icon: Icon, name }) => (
            <button
              key={name}
              onClick={() => handleReactionClick(name)}
              style={styles.reactionButton}
            >
              <Icon size={16} color={isUser ? '#1F484C' : '#FEFBF6'} />
            </button>
          ))}
        </div>
      )}

      {/* Message Content */}
      <div
        style={{
          ...styles.message,
          backgroundColor: isUser ? '#FEFBF6' : '#1F484C',
          color: isUser ? '#1F484C' : '#fff',
          border: isUser ? '1px solid #1F484C' : '1px solid #FEFBF6',
          borderColor: isUser ? '#1F484C' : '#fff',
          borderTopLeftRadius: !isUser ? '4px' : '18px',
          borderTopRightRadius: isUser ? '4px' : '18px',
        }}
      >
        {isEditing ? (
          <div style={styles.editContainer}>
            <textarea
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              style={{
                ...styles.editInput,
                color: isUser ? '#1F484C' : '#fff',
                backgroundColor: isUser ? '#FEFBF6' : '#1F484C',
                borderColor: isUser ? '#1F484C' : '#FEFBF6',
              }}
              autoFocus
            />
            <div style={styles.editActions}>
              <button
                onClick={handleEditSave}
                style={{
                  ...styles.editButton,
                  backgroundColor: isUser ? '#1F484C' : '#FEFBF6',
                  color: isUser ? '#FEFBF6' : '#1F484C',
                }}
              >
                Save
              </button>
              <button
                onClick={handleEditCancel}
                style={{
                  ...styles.editButton,
                  backgroundColor: 'transparent',
                  color: isUser ? '#1F484C' : '#FEFBF6',
                  border: `1px solid ${isUser ? '#1F484C' : '#FEFBF6'}`,
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <>
            <div style={styles.messageText}>
              {message.text}
              {message.edited && (
                <span style={styles.editedLabel}>(edited)</span>
              )}
            </div>
            <div style={styles.messageTime}>
              {message.timestamp}
            </div>
          </>
        )}
      </div>

      {/* Message Reactions */}
      {message.reactions && message.reactions.length > 0 && (
        <div
          style={{
            ...styles.reactionsList,
            justifyContent: isUser ? 'flex-end' : 'flex-start'
          }}
        >
          {message.reactions.map((reaction, index) => {
            const ReactionIcon = reactions.find(r => r.name === reaction)?.icon;
            return (
              <button
                key={index}
                onClick={() => handleReactionClick(reaction)}
                style={{
                  ...styles.reactionBubble,
                  cursor: 'pointer',
                  border: 'none',
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  ':hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  }
                }}
                title="Click to remove reaction"
              >
                {ReactionIcon && (
                  <ReactionIcon 
                    size={14} 
                    color={isUser ? '#1F484C' : '#FEFBF6'} 
                  />
                )}
              </button>
            );
          })}
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      {showDeleteConfirm && (
        <div style={styles.deleteConfirmOverlay}>
          <div style={styles.deleteConfirm}>
            <p style={styles.deleteConfirmText}>Delete this message?</p>
            <div style={styles.deleteConfirmActions}>
              <button
                onClick={() => {
                  onDelete(message.id);
                  setShowDeleteConfirm(false);
                }}
                style={{
                  ...styles.deleteConfirmButton,
                  backgroundColor: '#dc3545',
                  color: 'white'
                }}
              >
                Delete
              </button>
              <button
                onClick={() => setShowDeleteConfirm(false)}
                style={styles.deleteConfirmButton}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
    );
};

const styles = {
  messageWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '4px',
    position: 'relative',
    padding: '4px 0',
  },
  messageContainer: {
    position: 'relative',
    maxWidth: '60%',
  },
  message: {
    padding: '8px 12px',
    borderRadius: '18px',
    position: 'relative',
  },
  messageText: {
    fontSize: '14px',
    lineHeight: '1.4',
    wordWrap: 'break-word',
  },
  messageTime: {
    fontSize: '11px',
    opacity: 0.7,
    marginTop: '4px',
    textAlign: 'right',
  },
  avatarSmall: {
    width: '28px',
    height: '28px',
    backgroundColor: '#f3f4f6',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '4px',
  },
  avatarIconSmall: {
    fontSize: '14px',
    color: '#6b7280',
  },
  actionsContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
  },
  actionsMenu: {
    display: 'flex',
    gap: '4px',
    padding: '4px',
    backgroundColor: 'white',
    borderRadius: '20px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  },
  actionButton: {
    padding: '6px',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    color: '#65676B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
      backgroundColor: '#f0f2f5',
    },
  },
  actionsDot: {
    padding: '4px',
    backgroundColor: 'white',
    borderRadius: '50%',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  reactionsMenu: {
    position: 'absolute',
    top: '-40px',
    display: 'flex',
    gap: '4px',
    padding: '4px',
    backgroundColor: 'white',
    borderRadius: '20px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    zIndex: 1,
  },
  reactionBubble: {
    padding: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.2s, transform 0.2s',
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      transform: 'scale(1.1)',
    },
  },
  reactionsList: {
    display: 'flex',
    gap: '2px',
    marginTop: '4px',
  },
  reactionBubble: {
    padding: '2px 4px',
    backgroundColor: 'white',
    borderRadius: '10px',
    fontSize: '12px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
  },
  // quick reaction div 
  quickReactionsBar: {
    position: 'absolute',
    top: '-45px',
    backgroundColor: 'white',
    borderRadius: '30px',
    padding: '8px',
    display: 'flex',
    gap: '8px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
    zIndex: 2,
  },
  quickReactionButton: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '8px',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#65676B',
    transition: 'all 0.2s ease',
    ':hover': {
      backgroundColor: '#f0f2f5',
    },
  },
  // Update reactionBubble style
  reactionBubble: {
    padding: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // edit buttons
  verticalMenuWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
  },
  verticalMenuOptions: {
    position: 'absolute',
    top: '100%',
    right: '0',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '4px',
    marginTop: '4px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    minWidth: '120px',
    zIndex: 3,
  },
  menuOption: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 12px',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#1F484C',
    fontSize: '14px',
    width: '100%',
    textAlign: 'left',
    transition: 'background-color 0.2s',
    ':hover': {
      backgroundColor: '#f0f2f5',
    },
  },
  menuOptionIcon: {
    fontSize: '14px',
  }, 
  verticalMenuContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
  },
  verticalMenuButton: {
    padding: '8px',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#65676B',
    ':hover': {
      backgroundColor: '#f0f2f5',
    },
  },
  menuOption: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 12px',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#1F484C',
    fontSize: '14px',
    width: '100%',
    textAlign: 'left',
    ':hover': {
      backgroundColor: '#f0f2f5',
    },
  },
  menuOptionIcon: {
    fontSize: '14px',
  },
  editContainer: {
    width: '95%',
    resize: 'none', // Prevent manual resizing
  },
  editInput: {
    width: '100%',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    fontSize: '14px',
    backgroundColor: 'white',
    color: '#1F484C',
    resize: 'vertical',
    minHeight: '60px',
    maxHeight: '200px',
    marginBottom: '8px',
  },
  editActions: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
  },
  editButton: {
    padding: '4px 12px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '12px',
    backgroundColor: '#1F484C',
    color: 'white',
    transition: 'background-color 0.2s',
    ':hover': {
      backgroundColor: '#2a5962',
    },
  },
  editedLabel: {
    fontSize: '11px',
    color: 'inherit',
    opacity: 0.7,
    marginLeft: '4px',
  },
  deleteConfirmOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 3,
  },
  deleteConfirm: {
    backgroundColor: 'white',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
    maxWidth: '90%',
  },
  deleteConfirmText: {
    margin: '0 0 12px 0',
    fontSize: '14px',
    color: '#1F484C',
  },
  deleteConfirmActions: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
  },
  deleteConfirmButton: {
    padding: '6px 12px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '12px',
    backgroundColor: '#f0f2f5',
    color: '#1F484C',
    transition: 'background-color 0.2s',
    ':hover': {
      backgroundColor: '#e4e6eb',
    },
  },
};

export default Message;