import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const LandingPageBanner = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const additionalStyles = {
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      opacity: isHovered ? 1 : 0,
      transition: 'opacity 0.3s ease',
      borderRadius: '25px',
    },
    playCircle: {
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      border: '2px solid #fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '10px',
    },
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    },
    videoWrapper: {
      width: '80vw',
      height: '80vh',
      position: 'relative',
    },
    video: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      '-webkit-user-select': 'none', // Prevents text selection
      '-webkit-touch-callout': 'none', // Prevents touch callout
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      'user-select': 'none',
    },
    closeButton: {
      position: 'absolute',
      top: '-40px',
      right: '-40px',
      background: 'none',
      border: 'none',
      color: '#fff',
      fontSize: '30px',
      cursor: 'pointer',
    },
  };
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
      flexWrap: 'wrap',
    },
    columnLeft: {
      flex: '1 1 50%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '20px',
    },
    columnRight: {
      flex: '1 1 50%',
      position: 'relative',
      marginBottom: '20px',
    },
    charityName:{
      marginBottom: '0px', 
      letterSpacing: 10,
      fontSize: windowWidth <= 500 ? '10px' : '18px',
    },
    h1: {
      marginTop: '1px',
      fontSize: windowWidth <= 500 ? '50px' : '80px',
      lineHeight: windowWidth <= 500 ? '80px' : '120px',
      alignItems: 'top',
      fontWeight: 'bold', 
      marginBottom: '0px'
    },
    bars: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
      },
      bar1: {
        width: '100px',
        height: '10px',
        backgroundColor: 'red',
        marginRight: '10px',
        borderRadius: '90px'
      },
      bar2: {
        width: '100px',
        height: '10px',
        backgroundColor: 'orange',
        marginRight: '10px',
        borderRadius: '90px'
      },
      bar3: {
        width: '100px',
        height: '10px',
        backgroundColor: 'teal',
        borderRadius: '90px'
      },
    logo: {
      display: 'flex',
      marginTop: '20px',
      marginBottom: '20px'
    },
    logoImage: {
      height: '50px',
      width: '50px',
      marginRight: '10px',
    },
    playgroundImage: {
      maxWidth: '100%',
      borderRadius: '25px',
      boxShadow: 'rgba(0, 0, 0, 0.2) 7.8px 15.5px 15.5px',
      aspectRatio: windowWidth <= 768 ? '9/16' : '16/9', // Mobile vs Desktop ratio
      objectFit: 'cover',
    },
    videoContainer: {
      position: 'absolute',
      bottom: '20px',
      left: '20px',
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    },
    videoText: {
      margin: 0,
      fontSize: '16px',
      color: '#333',
    },
    videoButton: {
      backgroundColor: '#ff6b6b',
      border: 'none',
      borderRadius: '5px',
      padding: '10px 20px',
      color: '#fff',
      fontWeight: 'bold',
      cursor: 'pointer',
      marginTop: '10px',
      marginBottom: '10px',
    },
    videoButtonText: {
      fontSize: '16px',
    },
  
    '@media (max-width: 768px)': {
      columnLeft: {
        flex: '1 1 100%',
        marginRight: 0,
      },
      columnRight: {
        flex: '1 1 100%',
      },
      h1: {
        fontSize: '60px',
        lineHeight: '80px',
      },
      videoContainer: {
        position: 'none',
        marginTop: '20px',
      },
    },
  
    '@media (max-width: 480px)': {
      h1: {
        fontSize: '40px',
        lineHeight: '60px',
      },
      videoContainer: {
        position: 'none',
        marginTop: '20px',
      },
    },
  };

  const mergedStyles = { ...styles, ...additionalStyles };
  

  return (
    <div style={styles.container}>
      <div style={styles.columnLeft}>
        <p style={styles.charityName}>
          OFF THE CURRICULUM
        </p>
        <h1 style={styles.h1}>
        <span style={{
          display: 'block',
          fontWeight: '700',
          color: '#FF6B6B'
        }}>
          Igniting
        </span>
        <span style={{
          fontWeight: '700',
        }}>
          Imagination Outside 
        </span>
        <span style={{
          display: 'block',
          fontWeight: '700',
        }}>
          the Classroom
        </span></h1>
        <div style={styles.bars}>
          <div style={styles.bar1}></div>
          <div style={styles.bar2}></div>
          <div style={styles.bar3}></div>
        </div>
        <div style={styles.logo}>
          <img src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2Foct-logo.png?alt=media&token=d95bb7ea-f536-443c-ba89-165fffa6f86a" alt="London Youth Logo" style={styles.logoImage} />
          <img src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FLYMember.webp?alt=media&token=cac96dc2-f885-4173-855c-f8c58e9c3cff" alt="London Youth Logo" style={styles.logoImage} />
          <img src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FFooter%2FPurple_Badge_PolicyBee.webp?alt=media&token=eed7901b-7ce4-4cf2-8caa-65339ce55b61" alt="Policy Bee Logo" style={styles.logoImage} />
          <img src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2Flondon_youth_qm_bronze_accredited.png?alt=media&token=6da71fd5-a915-4497-92de-2eccd63bc68f" alt="Policy Bee Logo" style={styles.logoImage} />
        </div>
      </div>
      <div
      style={mergedStyles.columnRight}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      >
      <div style={mergedStyles.overlay} />
      <img 
        src={windowWidth <= 768 
          ? "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FmobileLandingVideo.png?alt=media&token=bc1deef1-42d8-42a5-95a5-8c41623d6ef4" // Replace with your mobile image URL
          : "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FlandingPageVideoImage.png?alt=media&token=eb48f5f3-fbd2-4f1b-b468-fc06f7944811"
        }
        alt="Playground" 
        style={mergedStyles.playgroundImage}
      />

        {isHovered && (
          <video
            style={{
              ...mergedStyles.playgroundImage,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            autoPlay
            muted
            loop
            controlsList="nodownload nofullscreen" 
            onContextMenu={(e) => e.preventDefault()} // Prevents right-click
          >
            <source src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FlandingVideoShort.mp4?alt=media&token=0ae6b446-2fd1-422d-b4cb-c3f2348c80f0" type="video/mp4" />
          </video>
        )}
        
        <motion.div 
          style={mergedStyles.videoContainer}
          animate={{ scale: isHovered ? 1.1 : 1 }}
          transition={{ duration: 0.3 }}
          onClick={() => setIsModalOpen(true)}
        >
          <p style={mergedStyles.videoText}>What is Off The Curriculum?</p>
          <button style={mergedStyles.videoButton}>
            <span style={mergedStyles.videoButtonText}>
              Watch our full video to find out more
            </span>
          </button>
        </motion.div>
      </div>

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            style={mergedStyles.modal}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsModalOpen(false)}
          >
            <div style={mergedStyles.videoWrapper} onClick={e => e.stopPropagation()}>
              <video 
                style={mergedStyles.video}
                controls
                autoPlay
                controlsList="nodownload nofullscreen" 
                onContextMenu={(e) => e.preventDefault()} // Prevents right-click
              >
                <source src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FofftheCurriculumProjectSeminarSeries.mp4?alt=media&token=3d5f4299-f6e8-4494-81a4-49d06b0d5926" type="video/mp4" />
              </video>
              <button 
                style={mergedStyles.closeButton}
                onClick={() => setIsModalOpen(false)}
              >
                ×
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LandingPageBanner;