// hooks/usePolicyDocument.js
import { useEffect, useState, useRef } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../Firebase/firebaseConfig';

const usePolicyDocument = (currentUser, policyId) => {
  const [documentUrl, setDocumentUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchingRef = useRef(false);

  useEffect(() => {
    // Skip if no user or policyId, or if already fetching
    if ( !policyId || fetchingRef.current) return;

    const fetchPolicyDocument = async () => {
      try {
        fetchingRef.current = true;
        setLoading(true);
        setError(null);
        console.log('policy id', policyId)

        const fetchPolicyPdfs = httpsCallable(functions, 'fetch_policy_pdfs');
        //console.log('hello', fetchPolicyPdfs)
        const result = await fetchPolicyPdfs({
          policyId: policyId
        });
        //console.log('hello', result)

        if (result.data.data && result.data.data.success) {
          setDocumentUrl(result.data.data.documentUrl);
        } else {
          throw new Error(result.data.data?.error || 'Policy document not found');
        }
      } catch (error) {
        //console.error('Error fetching policy:', error);
        setError('Failed to load policy document. Please try again later.');
      } finally {
        setLoading(false);
        fetchingRef.current = false;
      }
    };

    fetchPolicyDocument();
  }, [currentUser?.email, policyId]);

  return { documentUrl, loading, error };
};

export default usePolicyDocument;